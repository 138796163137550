import { FC } from 'react'
import { ItemT } from 'shared/types/model'
import _ from 'lodash'
import {
  HStack,
  Text,
  Image,
  VStack,
  Button,
  Flex,
  Badge,
  IconButton,
  Tooltip
} from '@chakra-ui/react'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faEdit,
  faEye,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { MpSdk } from 'shared/bundle/sdk'
import numeral from 'numeral'

type Props = {
  sdk: MpSdk
  item: ItemT
  index: number
  quantity: number
  openProductCard: (item: ItemT) => void
  addToRoom: (itemId: string) => void
  onRemove: () => void
  mattertagId?: string
  supplierName?: string
  onSelect: () => void
  canEdit?: boolean
  pricesVisible: boolean
}
const PanelCartItem: FC<Props> = ({
  sdk,
  item,
  index,
  quantity,
  openProductCard,
  onRemove,
  mattertagId,
  supplierName,
  addToRoom,
  onSelect,
  canEdit,
  pricesVisible
}) => {
  const navigateToItem = async () => {
    if (mattertagId) {
      sdk.Mattertag.navigateToTag(mattertagId, sdk.Mattertag.Transition.FLY)
    }
  }

  const onSelectClick = () => {
    navigateToItem()
    onSelect()
  }

  const renderButtons = () => {
    if (canEdit) {
      return (
        <Flex w='full' direction={'row'} justify='space-around' pt={2} h={8}>
          <Tooltip label='Edit'>
            <IconButton
              aria-label='edit item'
              icon={<FontAwesomeIcon icon={faEdit} fixedWidth />}
              variant='link'
              size={'sm'}
              colorScheme='blue'
              display='none'
              _groupHover={{ display: 'block' }}
              flex={1}
              onClick={onSelectClick}
            />
          </Tooltip>
          <Tooltip label='Duplicate'>
            <IconButton
              aria-label='duplicate item'
              icon={<FontAwesomeIcon icon={faCopy} fixedWidth />}
              variant='link'
              size={'sm'}
              colorScheme='blue'
              display='none'
              _groupHover={{ display: 'block' }}
              flex={1}
              onClick={() => addToRoom(item.id)}
            />
          </Tooltip>
          <Tooltip label='Remove'>
            <IconButton
              aria-label='remove'
              icon={<FontAwesomeIcon icon={faTrashCan} fixedWidth />}
              variant='link'
              size={'sm'}
              colorScheme='red'
              display='none'
              _groupHover={{ display: 'block' }}
              onClick={onRemove}
              flex={1}
            />
          </Tooltip>
        </Flex>
      )
    }
  }

  return (
    <VStack w='full' pb={4} _hover={{ bg: 'gray.200' }} px={4} role='group'>
      <HStack
        pt={4}
        bg='transparent'
        align={'flex-start'}
        w='full'
        spacing={4}
        scrollSnapAlign='start'
      >
        <VStack
          minW={'100px'}
          position='relative'
          justify={'flex-start'}
          spacing={0}
          h='full'
        >
          <Image boxSize='100' src={getItemPhotoUrl(item)} rounded={'lg'} />
          <Flex
            bg='whiteAlpha.700'
            position={'absolute'}
            w='100px'
            h='100px'
            left={0}
            top={0}
            rounded={'lg'}
            direction='column'
            justify={'center'}
            align='center'
            color='gray.600'
            as='button'
            onClick={navigateToItem}
            display='none'
            pt={4}
            _groupHover={{ display: 'flex' }}
          >
            <FontAwesomeIcon icon={faEye} size='2x' />
            <Text pt={1} fontSize='xs' color='gray.700' fontWeight={'semibold'}>
              View in room
            </Text>
          </Flex>
          {renderButtons()}
        </VStack>
        <VStack align={'flex-start'} w='full'>
          <Text fontWeight={'semibold'} lineHeight={1.2} color='blue.800'>
            {item.title}{' '}
            {quantity > 1 && (
              <Text as='span' fontWeight={'normal'} color='gray.500'>
                ({index + 1} of {quantity})
              </Text>
            )}
          </Text>
          {item.price && pricesVisible && (
            <Text fontSize='xs' fontWeight={'bold'}>
              {numeral(item.price).format('$0,0.00')}
            </Text>
          )}
          <Text lineHeight={1.2} color='gray.500' fontSize={'xs'} noOfLines={5}>
            {item.desc}
          </Text>
          <Flex w='full' justify={'space-between'}>
            <Button
              size='xs'
              variant={'link'}
              colorScheme='gray.600'
              iconSpacing={1}
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronRight}
                  color='gray.600'
                  fontSize={'0.8em'}
                />
              }
              onClick={() => openProductCard(item)}
            >
              More info
            </Button>
            <Badge colorScheme='green'>
              {_.truncate(supplierName, { length: 16 })}
            </Badge>
          </Flex>
        </VStack>
      </HStack>
    </VStack>
  )
}

export default PanelCartItem
