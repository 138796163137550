import { useRef } from 'react'
import _ from 'lodash'
import {
  Flex,
  Button,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  IconButton
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faGear, faPlus } from '@fortawesome/pro-regular-svg-icons'
import AddTagModal, { IAddTagModal } from 'modals/AddTagModal'
import { useSelector } from 'model/hooks'
import { TagT } from 'types/model'

const Tags = () => {
  const addTagModalRef = useRef<IAddTagModal>(null)
  const tags = useSelector(state => state.tags)

  const navBarButton = (
    <Button
      colorScheme={'teal'}
      leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
      onClick={() => addTagModalRef.current?.open()}
      size='sm'
    >
      Add tag
    </Button>
  )

  const renderTag = (t: TagT) => {
    return (
      <WrapItem key={t.id}>
        <Tag key={t.id} size={'md'} variant='subtle' colorScheme='blue'>
          <TagLabel pl={4}>{t.name}</TagLabel>
          <IconButton
            icon={<FontAwesomeIcon icon={faGear} />}
            aria-label='edit tag'
            variant={'unstyled'}
            size='sm'
            onClick={() => addTagModalRef.current?.open(t)}
          />
        </Tag>
      </WrapItem>
    )
  }

  return (
    <Flex direction='column' w='full' h='full'>
      <NavBar title={'Tags'}>{navBarButton}</NavBar>
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
        p={12}
      >
        <Wrap justify='center'>{_.map(tags, renderTag)}</Wrap>
      </Flex>
      <AddTagModal ref={addTagModalRef} />
    </Flex>
  )
}

export default Tags
