import { FC, MouseEvent, useRef, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  Image,
  Text,
  IconButton,
  Wrap,
  WrapItem,
  Tag,
  TagLabel
} from '@chakra-ui/react'
import ReactGridLayout, { Layout } from 'react-grid-layout'

import AddTemplateModal, { IAddTemplateModal } from 'modals/AddTemplateModal'
import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { DictT, RoomTypeT, ItemsTemplateT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faGripDotsVertical
} from '@fortawesome/pro-solid-svg-icons'
import { noImageUrl } from 'shared/constants/helpers'
import { useNavigate } from 'react-router'
import { faEdit, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { arrayMove } from 'shared/utils/array'
import { dbUpdateTemplatesOrder } from 'controllers/templates'
import { useSelector } from 'model/hooks'

type Props = {
  goBack: () => void
  roomType: RoomTypeT
  templates: DictT<ItemsTemplateT>
}

const TemplatesList: FC<Props> = ({ goBack, roomType, templates }) => {
  const navigate = useNavigate()
  const tags = useSelector(state => state.tags)

  const addTemplateModalRef = useRef<IAddTemplateModal>(null)

  const sortedTemplates = useMemo(() => {
    return _.sortBy(templates, 'displayOrder')
  }, [templates])

  const layout = useMemo(() => {
    return _.map(sortedTemplates, (s, i) => {
      return {
        i: s.id,
        x: i % 2,
        y: Math.trunc(i / 2),
        w: 1,
        h: 1,
        isResizable: false
      }
    })
  }, [sortedTemplates])

  const onSelectClick = (t: ItemsTemplateT) => {
    navigate(`/templates/${t.id}`)
  }

  const onEditTemplateClick = (
    e: MouseEvent<HTMLButtonElement>,
    tId: string
  ) => {
    e.stopPropagation()
    console.log('on edit', tId)
    addTemplateModalRef.current?.open(roomType.id, tId)
  }

  const renderCard = (t: ItemsTemplateT) => {
    return (
      <VStack
        align={'flex-start'}
        pb={4}
        w={'164px'}
        spacing={1}
        position='relative'
        role='group'
        key={t.id}
      >
        <Box
          as={'button'}
          // _hover={{ boxShadow: 'md' }}
          onClick={() => onSelectClick(t)}
          opacity={t.enabled ? 1 : 0.3}
        >
          <Image boxSize={164} alt={t.name} src={t.photo || noImageUrl} />
        </Box>
        <Wrap w='full' spacing={0.5}>
          {_.map(t.tags, tId => {
            return (
              <WrapItem key={tId}>
                <Tag
                  size='sm'
                  key={tId}
                  variant='subtle'
                  colorScheme='cyan'
                  mb={0.5}
                >
                  <TagLabel>{tags[tId].name}</TagLabel>
                </Tag>
              </WrapItem>
            )
          })}
        </Wrap>
        <Text
          fontSize={'xs'}
          color='gray.600'
          noOfLines={2}
          h='8'
          lineHeight={1.2}
        >
          {t.name}
        </Text>
        <HStack w='full' justify={'space-between'}>
          <Button
            size='sm'
            colorScheme={'teal'}
            w='full'
            onClick={() => onSelectClick(t)}
          >
            Select
          </Button>
          <Box cursor={'grab'}>
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </Box>
        </HStack>
        <Box position='absolute' top={0} right={2}>
          <IconButton
            display='none'
            variant='outline'
            color={'blue.400'}
            m={0}
            _groupHover={{ display: 'flex' }}
            size='sm'
            aria-label='edit_slot'
            icon={<FontAwesomeIcon icon={faEdit as IconProp} />}
            onClick={e => onEditTemplateClick(e, t.id)}
          />
        </Box>
      </VStack>
    )
  }

  const onDragStop = (layout: Layout[], oldItem: Layout, newItem: Layout) => {
    const oldIndex = oldItem.y * 2 + oldItem.x
    const newIndex = newItem.y * 2 + newItem.x
    const newSortedTemplates = arrayMove(sortedTemplates, oldIndex, newIndex)
    const templatesToUpdate: ItemsTemplateT[] = []
    _.forEach(newSortedTemplates, (item, i) => {
      if (item.displayOrder !== i) {
        templatesToUpdate.push({ ...item, displayOrder: i })
      }
    })
    if (!_.isEmpty(templatesToUpdate)) {
      dbUpdateTemplatesOrder(templatesToUpdate)
    }
  }

  const renderItems = () => {
    return (
      <Flex
        w='370px'
        h='full'
        overflowY='auto'
        overflowX={'hidden'}
        position={'relative'}
        pl={0}
        sx={{
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <ReactGridLayout
          // className='layout'
          cols={2}
          rowHeight={300}
          width={370}
          layout={layout}
          margin={[20, 20]}
          onDragStop={onDragStop}
          compactType='horizontal'
        >
          {_.map(templates, renderCard)}
        </ReactGridLayout>
      </Flex>
    )
  }

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='flex-start' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <Box as='button' onClick={() => goBack()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {roomType.name}
                </Heading>
              </VStack>
            </HStack>
            <Button
              size='xs'
              variant={'ghost'}
              color='blue.500'
              onClick={() => addTemplateModalRef.current?.open()}
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              Add template
            </Button>
          </Flex>
        </Box>
        <Flex
          direction='column'
          align={'flex-start'}
          justify='flex-start'
          flex={1}
          // h='full'
          overflow='hidden'
          px={4}
        >
          {renderItems()}
        </Flex>
        <AddTemplateModal ref={addTemplateModalRef} />
      </Flex>
    </RightPanelWrapper>
  )
}

export default TemplatesList
