import { FC, MouseEvent, useRef, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  // Wrap,
  // WrapItem,
  Image,
  Text,
  IconButton
} from '@chakra-ui/react'
import ReactGridLayout, { Layout } from 'react-grid-layout'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { DictT, SlotT, ItemT } from 'shared/types/model'
import AddSlotModal, { IAddSlotModal } from 'modals/AddSlotModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useSelector } from 'model/hooks'
import { getItemsBySlot } from 'model/selectors/itemsSelector'
import { arrayMove } from 'shared/utils/array'
import { dbUpdateSlotsOrder } from 'controllers/slots'

type Props = {
  goBack: () => void
  selectCategory: (slotId: string) => void
  selectedItemId: string | null
  slots: DictT<SlotT>
}

const CatalogPanel: FC<Props> = ({
  // goBack,
  selectCategory,
  // selectedItemId,
  slots
}) => {
  const addSlotModalRef = useRef<IAddSlotModal>(null)
  const itemsBySlot = useSelector(getItemsBySlot)
  const items = useSelector(state => state.items)

  const enabledItemsGroups = useMemo(() => {
    const enabledItems = _.filter(items, (item: ItemT) =>
      _.get(item, 'enabled', false)
    )
    return _.groupBy(enabledItems, 'kind')
  }, [items])

  const onEditSlotClick = (
    e: MouseEvent<HTMLButtonElement>,
    slotId: string
  ) => {
    e.stopPropagation()
    console.log('on edit', slotId)
    addSlotModalRef.current?.open(slotId)
  }

  const sortedSlots = useMemo(() => {
    return _.sortBy(slots, 'displayOrder')
  }, [slots])

  const layout = useMemo(() => {
    return _.map(sortedSlots, (s, i) => {
      return {
        i: s.id,
        x: i % 2,
        y: Math.trunc(i / 2),
        w: 1,
        h: 1,
        isResizable: false
        // isBounded: true
      }
    })
  }, [sortedSlots])

  const renderSlot = (s: SlotT) => {
    const enabledAmount = _.size(_.get(enabledItemsGroups, s.id))
    const amount = _.size(itemsBySlot[s.id])
    return (
      <VStack
        key={s.id}
        align={'flex-start'}
        pb={4}
        role='group'
        position={'relative'}
        w={'164px'}
      >
        <Box
          as='button'
          _hover={{ boxShadow: 'md' }}
          onClick={() => selectCategory(s.id)}
        >
          <Image
            boxSize={164}
            alt={s.title}
            src={s.photo?.thumbnailUrl || s.photo?.url}
          />
        </Box>
        <HStack justify={'space-between'} w='full'>
          <Text fontSize={'xs'} color='blackAlpha.900' noOfLines={2}>
            {s.title} ({enabledAmount}/{amount})
          </Text>
          <Box cursor={'grab'}>
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </Box>
        </HStack>
        <Box position='absolute' top={0} right={2}>
          <IconButton
            display='none'
            variant='outline'
            color={'blue.400'}
            m={0}
            _groupHover={{ display: 'flex' }}
            size='sm'
            aria-label='edit_slot'
            icon={<FontAwesomeIcon icon={faEdit as IconProp} />}
            onClick={e => onEditSlotClick(e, s.id)}
          />
        </Box>
      </VStack>
    )
  }

  // const renderCategories = () => {
  //   return (
  //     <Wrap
  //       spacing={4}
  //       overflowY='auto'
  //       scrollSnapType={'y proximity'}
  //       sx={{
  //         '-ms-overflow-style': 'none',
  //         scrollbarWidth: 'none',
  //         '::-webkit-scrollbar': { display: 'none' }
  //       }}
  //     >
  //       {_.map(slots, s => {
  //         return (
  //           <WrapItem key={s.id} scrollSnapAlign='start'>
  //             {renderSlot(s)}
  //           </WrapItem>
  //         )
  //       })}
  //     </Wrap>
  //   )
  // }

  const onDragStop = (
    layout: Layout[],
    oldItem: Layout,
    newItem: Layout,
    placeholder: Layout
    // e: MouseEvent<HTMLElement, MouseEvent>,
    // element: HTMLElement
  ) => {
    console.log('onDragStop: layout', layout)
    console.log('oldItem', oldItem)
    console.log('newItem', newItem)
    console.log('placeholer', placeholder)
    const oldIndex = oldItem.y * 2 + oldItem.x
    const newIndex = newItem.y * 2 + newItem.x
    const newSortedSlots = arrayMove(sortedSlots, oldIndex, newIndex)
    dbUpdateSlotsOrder(newSortedSlots)
  }

  const renderCategories = () => {
    console.log('layout', layout)
    return (
      <Flex
        w='370px'
        h='full'
        overflowY='auto'
        overflowX={'hidden'}
        position={'relative'}
        pl={0}
      >
        <ReactGridLayout
          // className='layout'
          cols={2}
          rowHeight={200}
          width={370}
          layout={layout}
          margin={[20, 20]}
          onDragStop={onDragStop}
        >
          {_.map(slots, renderSlot)}
        </ReactGridLayout>
      </Flex>
    )
  }

  const header = (
    <Box pb={8} bg='white' p={4}>
      <Flex align='flex-start' justify={'space-between'}>
        <HStack align='flex-start' spacing={4} w='full'>
          <Flex align={'center'} justify='space-between' w={'full'}>
            <VStack align={'flex-start'}>
              <Heading size='md' color='black'>
                Catalog
              </Heading>
            </VStack>
            <Button
              size='xs'
              variant={'ghost'}
              color='blue.500'
              onClick={() => addSlotModalRef.current?.open()}
            >
              Add category
            </Button>
          </Flex>
        </HStack>
      </Flex>
    </Box>
  )

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        {header}
        <Flex
          direction='column'
          align={'center'}
          justify='flex-start'
          flex={1}
          h='full'
          overflow={'hidden'}
          p={4}
        >
          {renderCategories()}
        </Flex>
      </Flex>
      <AddSlotModal ref={addSlotModalRef} />
    </RightPanelWrapper>
  )
}

export default CatalogPanel
