import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { ItemT } from 'shared/types/model'
import { createItem } from 'controllers/items'

export interface IAddItemModal {
  open: (slotId: string) => void
}

type Props = {
  onSelectItem: (itemId: string) => void
}

const AddItemModal: ForwardRefRenderFunction<IAddItemModal, Props> = (
  { onSelectItem }: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [sId, setSId] = useState<string | undefined>()

  useImperativeHandle(ref, () => ({
    open: (slotId: string) => {
      setSId(slotId)
      onOpen()
    }
  }))

  const canCreate = () => {
    return !_.isEmpty(name)
  }

  const onCreateClick = () => {
    const id = generateId()
    if (sId) {
      const item: ItemT = {
        id,
        title: name,
        createdAt: _.now(),
        kind: sId,
        displayOrder: 0
      }
      console.log('create item', item)
      createItem(item)
      onSelectItem(id)
      setName('')
    } else {
      console.error('create item error, sId or pId are empty', sId)
    }
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor='slotName'>Name</FormLabel>
            <Input
              value={name}
              placeholder='item name'
              onChange={e => setName(e.target.value)}
            />
          </FormControl>
          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              colorScheme={'green'}
              ml={2}
              disabled={!canCreate()}
              onClick={onCreateClick}
            >
              Create
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddItemModal)
