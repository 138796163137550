import { useRef } from 'react'
import { Flex, Button, HStack } from '@chakra-ui/react'
import PartnersContent from 'pages/partners/PartnersContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import NavBar from 'components/NavBar'
import AddPartnerModal, { IAddPartnerModal } from 'modals/AddPartnerModal'
import { PartnerT } from 'types/model'
// import config from 'shared/config'
// import { syncSuppliers } from 'controllers/sync'

const Partners = () => {
  // const [syncing, setSyncing] = useState(false)
  // const toast = useToast()
  const addPartnerRef = useRef<IAddPartnerModal>(null)

  const navBarButton = (
    <HStack>
      {/* {config.isDev && (
        <Button
          colorScheme={'blue'}
          leftIcon={<FontAwesomeIcon icon={faArrowUpFromArc} />}
          onClick={onSyncSuppliersClick}
          size='sm'
          isLoading={syncing}
          variant='outline'
        >
          Sync to production
        </Button>
      )} */}
      <Button
        colorScheme={'teal'}
        leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
        onClick={() => addPartnerRef.current?.open()}
        size='sm'
      >
        Add partner
      </Button>
    </HStack>
  )
  return (
    <Flex direction='column' w='full' h='full'>
      <NavBar title={'Partners'}>{navBarButton}</NavBar>
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <PartnersContent
          onEditPartner={(p: PartnerT) => addPartnerRef.current?.open(p)}
        />
      </Flex>
      <AddPartnerModal ref={addPartnerRef} />
    </Flex>
  )
}

export default Partners
