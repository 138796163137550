import { RootState } from 'model/store'
import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'

export const getItems = (state: RootState) => state.items
export const getSlots = (state: RootState) => state.slots
export const getTourModels = (state: RootState) => state.tourModels
export const getTemplates = (state: RootState) => state.templates
export const getRoomTypes = (state: RootState) => state.roomTypes

export const getAppLoaded = createSelector(
  [getItems, getSlots, getTourModels],
  (items, slots, tourModels) => {
    const lst = [items, slots, tourModels]
    const doneAmount = _.reduce(
      lst,
      (res, elt) => {
        return _.isNil(elt) ? res : res + 1
      },
      0
    )
    return doneAmount === _.size(lst) ? null : (doneAmount / _.size(lst)) * 100
  }
)
