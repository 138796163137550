import { Flex } from '@chakra-ui/react'
import SettingsContent from 'pages/settings/SettingsContent'
import NavBar from 'components/NavBar'

const Settings = () => {
  return (
    <Flex direction='column' w='full' h='full'>
      <NavBar title='Settings' />
      <Flex
        direction={'column'}
        w='full'
        align={'center'}
        overflow={'hidden'}
        flex={1}
        pt={12}
      >
        <SettingsContent />
      </Flex>
    </Flex>
  )
}

export default Settings
