import _ from 'lodash'
import { onAuthStateChanged, User } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { NavigateFunction } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { receiveAuthData, logout } from 'model/actions'
import store from 'model/store'
import { auth, db } from 'controllers/db'
import { dbSignOut } from 'controllers/auth'
import { fetchSlots } from 'controllers/slots'
import { fetchItems } from 'controllers/items'
import { fetchTourModels } from 'controllers/tours'
import { fetchSuppliers } from 'controllers/suppliers'
// import { fetchSettings } from 'controllers/settings'
import { fetchDesigners } from 'controllers/designers'
import { fetchRequestsDesign } from 'controllers/requestDesign'
import { fetchRoomTypes } from 'controllers/roomTypes'
import { fetchTemplates } from 'controllers/templates'
import { fetchCustomers } from 'controllers/customers'
import { fetchTags } from 'controllers/tags'
import { fetchPartners } from 'controllers/partners'

const init = async () => {
  // fetchSettings()
  try {
    await Promise.all([
      fetchTags(),
      fetchSlots(),
      fetchItems(),
      fetchTourModels(),
      fetchSuppliers(),
      fetchDesigners(),
      fetchRequestsDesign(),
      fetchRoomTypes(),
      fetchTemplates(),
      fetchCustomers(),
      fetchPartners()
    ])
  } catch (e) {
    Sentry.captureException(e)
  }
}

const checkIsAdmin = async (uid: string) => {
  const docSn = await getDoc(doc(db, 'admins', uid))
  return docSn.exists()
}

const onAuth = async (authData: User | null, navigate: NavigateFunction) => {
  console.log('onAuthStateChanged', authData)
  if (authData) {
    console.log('authData', authData)
    const { uid } = authData
    const isAdmin = await checkIsAdmin(uid)
    if (isAdmin) {
      store.dispatch(
        receiveAuthData({ uid: authData.uid, email: authData.email })
      )
      const pathname = window.location.pathname
      if (_.startsWith(pathname, '/sign')) {
        navigate('/')
      }
      init()
    } else {
      dbSignOut(navigate)
      navigate('/signin', { state: { notAdmin: true } })
    }
  } else {
    store.dispatch(receiveAuthData(null))
    // clearListeners()
    navigate('/signin')
    store.dispatch(logout())
  }
}

export const appInitialized = (navigate: NavigateFunction): void => {
  try {
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    Sentry.captureException(e)
    console.log('app initialization error', e)
  }
}
