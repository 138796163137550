import { useMemo } from 'react'
import { Flex, Text, Button, HStack, Badge } from '@chakra-ui/react'
import BaseTable from 'react-base-table'
import { useMeasure } from 'react-use'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'

import { RequestDesignT, TourModelT } from 'types/model'
import { useSelector } from 'model/hooks'
import config from 'shared/config'

enum RDStatusT {
  UNPAID = 'Unpaid',
  NEW = 'New',
  IN_PROGRESS = 'In progress',
  DONE = 'Done'
}

type RowT = {
  id: string
  name: string
  email: string
  timestamp: number
  address: string
  sf: string
  modelId: string
  status: RDStatusT
  invoiceUrl?: string
  tourId: string | null
  tourModelId: string | null
}

const RequestsDesignContent = () => {
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()
  const requests = useSelector(state => state.requestsDesign)
  const customers = useSelector(state => state.customers)
  const tourModels = useSelector(state => state.tourModels)
  // const modalRef = useRef<ISetSFModal>(null)

  const onViewClick = (modelId: string) => {
    console.log('onViewClick', modelId)
    window.open(
      `https://my.matterport.com/show/?m=${modelId}&play=1&ts=5&qs=1&brand=0&dh=1&gt=1&hr=1&mls=2&mt=0&pin=0&portal=0&f=1&lang=en&nozoom=0&wh=1&kb=1&lp=0&title=0&tourcta=0&vr=1`,
      '_blank'
    )
  }

  const onDoneViewClick = (tourId: string, tourModelId: string) => {
    window.open(`${config.userAppUrl}/${tourModelId}?d=${tourId}`, '_blank')
  }

  // const onSetSFClick = (rdId: string) => {
  //   console.log('onSetSFClick', rdId)
  //   modalRef.current?.open(rdId)
  // }

  const getRDStatus = (rd: RequestDesignT) => {
    if (rd.stripeInvoice && rd.stripeInvoice.paid) {
      if (_.isNil(rd.assignedTo)) {
        return RDStatusT.NEW
      } else if (rd.completed) {
        return RDStatusT.DONE
      } else {
        return RDStatusT.IN_PROGRESS
      }
    } else {
      return RDStatusT.UNPAID
    }
  }

  const data = useMemo(() => {
    if (requests && tourModels) {
      const rows = _.map(requests, (rd: RequestDesignT) => {
        const tm: TourModelT = tourModels[rd.tourModelId]
        const address = _.get(tm, 'address.name', '')
        const userId = rd.userId || ''
        const customer = _.get(customers, userId)
        const row: RowT = {
          id: rd.id,
          name: _.get(customer, 'name', ''),
          email: _.get(customer, 'email', ''),
          timestamp: rd.createdAt,
          address,
          sf: rd.sf ? _.toString(rd.sf) : '-',
          status: getRDStatus(rd),
          modelId: tm.modelId,
          tourId: rd?.tourId || null,
          invoiceUrl: _.get(rd.stripeInvoice, 'url'),
          tourModelId: _.get(rd, 'tourModelId', null)
        }
        return row
      })
      return _.orderBy(rows, ['timestamp'], 'desc')
    } else {
      return []
    }
  }, [requests])

  const columns = [
    {
      flexGrow: 1,
      key: 'name',
      dataKey: 'name',
      width: 0,
      title: 'Name',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    {
      flexGrow: 1.5,
      key: 'email',
      dataKey: 'email',
      width: 0,
      title: 'Email',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'timestamp',
      dataKey: 'timestamp',
      width: 0,
      title: 'Requested At',
      cellRenderer: ({ cellData }: { cellData: number }) => (
        <Text fontSize={'xs'}>{moment(cellData).format('lll')}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'address',
      dataKey: 'address',
      width: 0,
      title: 'Address',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    {
      flexGrow: 0,
      key: 'sf',
      dataKey: 'sf',
      width: 120,
      title: 'SF',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    {
      flexGrow: 0,
      key: 'invoiceUrl',
      dataKey: 'invoiceUrl',
      width: 120,
      title: 'Invoice',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Button
          size='sm'
          variant={'link'}
          onClick={() => window.open(cellData, '_blank')}
        >
          Invoice
        </Button>
      )
    },
    {
      flexGrow: 0,
      key: 'status',
      dataKey: 'status',
      width: 120,
      title: 'Status',
      cellRenderer: ({ cellData }: { cellData: RDStatusT }) => {
        let colorScheme = 'green'
        switch (cellData) {
          case RDStatusT.NEW:
            colorScheme = 'purple'
            break
          case RDStatusT.IN_PROGRESS:
            colorScheme = 'gray'
            break
          case RDStatusT.UNPAID:
            colorScheme = 'red'
            break
          case RDStatusT.DONE:
            colorScheme = 'green'
            break
        }
        return <Badge colorScheme={colorScheme}>{cellData}</Badge>
      }
    },
    {
      key: 'action',
      width: 125,
      title: 'Actions',
      cellRenderer: ({ rowData }: { rowData: RowT }) => (
        <HStack>
          <Button
            size='xs'
            leftIcon={<FontAwesomeIcon icon={faEye} />}
            onClick={() => {
              if (rowData.status === 'Done' && rowData.tourId && rowData.tourModelId) {
                onDoneViewClick(rowData.tourId, rowData.tourModelId)
              } else {
                onViewClick(rowData.modelId)
              }
            }}
          >
            {rowData.status === 'Done' ? 'View design' : 'View tour'}
          </Button>
        </HStack>
      )
    }
  ]

  return (
    <Flex flex={1} direction={'row'} grow={1} p={4} pt={2} ref={containerRef}>
      <BaseTable
        data={data}
        width={width}
        height={height}
        columns={columns}
        rowHeight={50}
        ignoreFunctionInColumnCompare={false}
      />
    </Flex>
  )
}

export default RequestsDesignContent
