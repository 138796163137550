import { FC, useRef, MouseEvent, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  VStack,
  HStack,
  Image,
  Text,
  IconButton,
  Button
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ReactGridLayout, { Layout } from 'react-grid-layout'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import {
  faChevronLeft,
  faEdit,
  faPlus,
  faGripDotsVertical
} from '@fortawesome/pro-solid-svg-icons'
import { RoomTypeT } from 'shared/types/model'
import { noImageUrl } from 'shared/constants/helpers'
import AddRoomType, { IAddRoomType } from 'modals/AddRoomType'
import { arrayMove } from 'shared/utils/array'
import { dbUpdateRoomTypesOrder } from 'controllers/roomTypes'

type Props = {
  goBack: () => void
  selectRoomType: (rtId: string) => void
  roomTypes: RoomTypeT[]
}

const CatalogPanel: FC<Props> = ({ goBack, selectRoomType, roomTypes }) => {
  const addRoomTypeModalRef = useRef<IAddRoomType>(null)

  const sortedRoomTypes = useMemo(() => {
    return _.sortBy(roomTypes, 'displayOrder')
  }, [roomTypes])

  const layout = useMemo(() => {
    return _.map(sortedRoomTypes, (s, i) => {
      return {
        i: s.id,
        x: i % 2,
        y: Math.trunc(i / 2),
        w: 1,
        h: 1,
        isResizable: false
        // isBounded: true
      }
    })
  }, [sortedRoomTypes])

  const onEditRTCLick = (e: MouseEvent<HTMLButtonElement>, rtId: string) => {
    e.stopPropagation()
    console.log('on edit', rtId)
    addRoomTypeModalRef.current?.open(rtId)
  }

  const onAddRoomTypeClick = () => {
    addRoomTypeModalRef.current?.open()
  }

  const renderCard = (rt: RoomTypeT) => {
    return (
      <VStack
        key={rt.id}
        align={'flex-start'}
        pb={4}
        role='group'
        position='relative'
        w={'164px'}
      >
        <Box
          as='button'
          _hover={{ boxShadow: 'md' }}
          onClick={() => selectRoomType(rt.id)}
        >
          <Image boxSize={164} alt={rt.name} src={rt.photo || noImageUrl} />
        </Box>
        <HStack justify={'space-between'} w='full'>
          <Text fontSize={'xs'} color='blackAlpha.900'>
            {rt.name}
          </Text>
          <Box cursor={'grab'}>
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </Box>
        </HStack>
        <Box position='absolute' top={0} right={2}>
          <IconButton
            display='none'
            variant='outline'
            color={'blue.400'}
            m={0}
            _groupHover={{ display: 'flex' }}
            size='sm'
            aria-label='edit_slot'
            icon={<FontAwesomeIcon icon={faEdit as IconProp} />}
            onClick={e => onEditRTCLick(e, rt.id)}
          />
        </Box>
      </VStack>
    )
  }

  const onDragStop = (layout: Layout[], oldItem: Layout, newItem: Layout) => {
    const oldIndex = oldItem.y * 2 + oldItem.x
    const newIndex = newItem.y * 2 + newItem.x
    const newSortedRoomTypes = arrayMove(sortedRoomTypes, oldIndex, newIndex)
    dbUpdateRoomTypesOrder(newSortedRoomTypes)
  }

  const renderCategories = () => {
    return (
      <Flex
        w='370px'
        h='full'
        overflowY='auto'
        overflowX={'hidden'}
        position={'relative'}
        pl={0}
        sx={{
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <ReactGridLayout
          // className='layout'
          cols={2}
          rowHeight={200}
          width={370}
          layout={layout}
          margin={[20, 20]}
          onDragStop={onDragStop}
          compactType='horizontal'
        >
          {_.map(roomTypes, renderCard)}
        </ReactGridLayout>
      </Flex>
    )
  }

  const header = (
    <Box pb={8} bg='white' p={4}>
      <Flex align='flex-start' justify={'space-between'}>
        <HStack align='flex-start' spacing={4} w='full'>
          <Flex align={'center'} justify='space-between' w={'full'}>
            <HStack align={'flex-start'}>
              <Box as='button' onClick={() => goBack()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <Heading size='md' color='black'>
                Room types
              </Heading>
            </HStack>
            <Button
              size='xs'
              variant={'ghost'}
              color='blue.500'
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={onAddRoomTypeClick}
            >
              Add room type
            </Button>
          </Flex>
        </HStack>
      </Flex>
    </Box>
  )

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        {header}
        <Flex
          direction='column'
          align={'flex-start'}
          justify='flex-start'
          flex={1}
          h='full'
          overflow={'hidden'}
          p={4}
        >
          {renderCategories()}
        </Flex>
      </Flex>
      <AddRoomType ref={addRoomTypeModalRef} />
    </RightPanelWrapper>
  )
}

export default CatalogPanel
