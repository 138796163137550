import { useRef, useState } from 'react'
import { Flex, Button, HStack, useToast } from '@chakra-ui/react'
import SuppliersContent from 'pages/suppliers/SuppliersContent'
import AddSupplierModal, { IAddSupplierModal } from 'modals/AddSupplierModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowUpFromArc, faPlus } from '@fortawesome/pro-regular-svg-icons'
import NavBar from 'components/NavBar'
import config from 'shared/config'
import { syncSuppliers } from 'controllers/sync'

const Suppliers = () => {
  const [syncing, setSyncing] = useState(false)
  const toast = useToast()
  const addSupplierRef = useRef<IAddSupplierModal>(null)

  const onSyncSuppliersClick = async () => {
    setSyncing(true)
    const success = await syncSuppliers()
    console.log('syncSuppliers success', success)
    setSyncing(false)
    toast({
      title: success
        ? 'Suppliers were successfully synced to production DB'
        : 'Sync error: check console log',
      status: success ? 'success' : 'error',
      duration: 5000,
      isClosable: true
    })
  }

  const navBarButton = (
    <HStack>
      {config.isDev && (
        <Button
          colorScheme={'blue'}
          leftIcon={<FontAwesomeIcon icon={faArrowUpFromArc} />}
          onClick={onSyncSuppliersClick}
          size='sm'
          isLoading={syncing}
          variant='outline'
        >
          Sync to production
        </Button>
      )}
      <Button
        colorScheme={'teal'}
        leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
        onClick={() => addSupplierRef.current?.open()}
        size='sm'
      >
        Add supplier
      </Button>
    </HStack>
  )
  return (
    <Flex direction='column' w='full' h='full'>
      <NavBar title={'Suppliers'}>{navBarButton}</NavBar>
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <SuppliersContent />
      </Flex>
      <AddSupplierModal ref={addSupplierRef} />
    </Flex>
  )
}

export default Suppliers
