import { Unsubscribe } from 'firebase/auth'
import _ from 'lodash'

interface Listeners {
  [key: string]: Unsubscribe
}

let listeners: Listeners = {}

// export let existingUser = null

// export const setExistingUser = user => {
//   existingUser = user
// }

export function addListener (key: string, l: Unsubscribe) {
  const existingUnsubscribe = _.get(listeners, key)
  if (existingUnsubscribe) existingUnsubscribe()
  listeners[key] = l
}

export function hasListener (key: string) {
  return _.has(listeners, key)
}

export function clearListeners () {
  console.log('clear listeners:')
  // setExistingUser(undefined)
  for (const key in listeners) {
    console.log('clear listener', key)
    const l = _.get(listeners, key)
    // console.log('off:', l)
    l && l()
  }

  listeners = {}
}

export function offListener (key: string) {
  const l = _.get(listeners, key)
  if (l) {
    // l.off()
    l()
    delete listeners[key]
  }
}

// let userUnsubscribe

// export function setUserUnsubscribe (unsubscribe) {
//   userUnsubscribe = unsubscribe
// }

// export function clearUserListener () {
//   if (userUnsubscribe) userUnsubscribe()
// }
