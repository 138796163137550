import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import NavBarBase from 'shared/components/NavBar'
import { useSelector } from 'model/hooks'
import { dbSignOut } from 'controllers/auth'

type Props = {
  title: ReactNode
  children?: ReactNode
  subheading?: string
}

const NavBar: FC<Props> = ({ children, title, subheading }) => {
  const authData = useSelector(state => state.authData)
  const navigate = useNavigate()

  return (
    <NavBarBase
      title={title}
      subheading={subheading}
      user={{
        name: 'Admin',
        email: authData && authData.email ? authData.email : ''
      }}
      signOut={() => dbSignOut(navigate)}
    >
      {children}
    </NavBarBase>
  )
}

export default NavBar
