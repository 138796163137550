import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Text,
  Button,
  Image,
  Wrap,
  WrapItem,
  Tag,
  TagLabel,
  Checkbox,
  VStack
} from '@chakra-ui/react'
import moment from 'moment'
import { useSelector } from 'model/hooks'
import BaseTable from 'react-base-table'
import { useMeasure } from 'react-use'
import { DictT, PartnerT } from 'shared/types/model'

type Props = {
  onEditPartner: (partner: PartnerT) => void
}

const PartnersContent: FC<Props> = ({ onEditPartner }) => {
  const partners: DictT<PartnerT> = useSelector(state => state.partners)
  const tags = useSelector(state => state.tags)
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()

  const data = useMemo(() => {
    return _.sortBy(partners, ['createdAt'])
  }, [partners])

  const columns = [
    {
      flexGrow: 1,
      key: 'name',
      dataKey: 'name',
      width: 0,
      title: 'Name',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: string
        rowData: PartnerT
      }) => (
        <Button variant='link' onClick={() => onEditPartner(rowData)}>
          {cellData}
        </Button>
      )
    },
    {
      flexGrow: 1,
      key: 'logo',
      dataKey: 'logoUrl',
      width: 0,
      title: 'Logo',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Image h={30} src={cellData} />
      )
    },
    {
      flexGrow: 0.6,
      key: 'subdomain',
      dataKey: 'subdomain',
      width: 0,
      title: 'Subdomain',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text>{cellData}</Text>
      )
    },
    {
      flexGrow: 1.5,
      key: 'contacts',
      dataKey: 'contacts',
      width: 0,
      title: 'Contacts',
      cellRenderer: ({ rowData }: { cellData: string; rowData: PartnerT }) => (
        <VStack align={'flex-start'} spacing={0}>
          <Text>{rowData.email}</Text>
          <Text>{rowData.phone}</Text>
        </VStack>
      )
    },
    {
      flexGrow: 1.5,
      key: 'Auth page text',
      dataKey: 'authPageText',
      width: 0,
      title: 'Auth page text',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Flex my={2} h='fit-content'>
          <Text>{cellData}</Text>
        </Flex>
      )
    },
    {
      flexGrow: 1.5,
      key: 'tags',
      dataKey: 'tags',
      width: 0,
      title: 'Tags',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Wrap w='full' spacing={0.5}>
          {_.map(cellData, tId => {
            return (
              <WrapItem key={tId}>
                <Tag
                  size='sm'
                  key={tId}
                  variant='subtle'
                  colorScheme='cyan'
                  mb={0.5}
                >
                  <TagLabel>{tags[tId].name}</TagLabel>
                </Tag>
              </WrapItem>
            )
          })}
        </Wrap>
      )
    },
    {
      flexGrow: 0.6,
      key: 'pricesVisible',
      dataKey: 'pricesVisible',
      width: 0,
      title: 'Prices Visible',
      cellRenderer: ({ cellData }: { cellData: boolean | undefined }) => (
        <Checkbox isChecked={cellData} isDisabled />
      )
    },
    {
      flexGrow: 1,
      key: 'createdAt',
      dataKey: 'createdAt',
      width: 0,
      title: 'Created At',
      cellRenderer: ({ cellData }: { cellData: number }) => (
        <Text>{moment(cellData).format('lll')}</Text>
      )
    }
  ]

  return (
    <Flex flex={1} direction={'row'} grow={1} p={4} ref={containerRef}>
      <BaseTable
        data={data}
        width={width}
        height={height}
        columns={columns}
        estimatedRowHeight={40}
        ignoreFunctionInColumnCompare={false}
        rowEventHandlers={{
          onClick: ({ rowData }: { rowData: PartnerT }) =>
            onEditPartner(rowData)
        }}
      />
    </Flex>
  )
}

export default PartnersContent
