import { FC } from 'react'

type Props = {
  visible: boolean
  tourId: string
  tourModelId: string
}

const EditTourNavBar: FC<Props> = () => {
  return null
}

export default EditTourNavBar
