import { Badge, Box } from '@chakra-ui/react'
import config from 'shared/config'

const DevIndicator = () => {
  return (
    <Box position='absolute' bottom={0} left={0}>
      <Badge size='sm' colorScheme={'orange'}>
        V{config.internalVersion}
      </Badge>
    </Box>
  )
}

export default DevIndicator
