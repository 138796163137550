import { createReducer } from '@reduxjs/toolkit'
import { DictT, DesignerT } from 'types/model'
import { receiveDesigners, clear, logout } from 'model/actions'

const initialState = {} as DictT<DesignerT>

const designersReducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveDesigners, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default designersReducer
