import {
  onSnapshot,
  query,
  collection,
  setDoc,
  doc,
  updateDoc,
  writeBatch,
  arrayRemove
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { TagT } from 'shared/types/model'
import { receiveTags } from 'model/actions'

export const fetchTags = () => {
  try {
    const q = query(collection(db, 'tags'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveTags(res))
      },
      err => {
        console.log(`fetchTags error: ${err}`)
      }
    )
    addListener('tags', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchTags error', e)
  }
}

export const dbUpdateTag = async (tagId: string, upd: Partial<TagT>) => {
  try {
    await updateDoc(doc(db, 'tags', tagId), upd)
  } catch (e) {
    Sentry.captureException(e)
    console.log('dbUpdateTag error', e)
  }
}

export const dbCreateTag = async (tag: TagT) => {
  try {
    await setDoc(doc(db, 'tags', tag.id), tag)
  } catch (e) {
    Sentry.captureException(e)
    console.log('createTag error', e)
  }
}

export const dbDeleteTag = async (tagId: string) => {
  const batch = writeBatch(db)
  batch.delete(doc(db, 'tags', tagId))
  const items = store.getState().items
  _.forEach(items, item => {
    if (item.tags && _.includes(item.tags, tagId)) {
      batch.update(doc(db, 'furnitures', item.id), { tags: arrayRemove(tagId) })
    }
  })
  await batch.commit()
}
