import {
  DictT,
  RoomTypeT,
  DesignerT,
  ItemT,
  RequestDesignT,
  SettingsT,
  SlotT,
  SupplierT,
  TourModelT,
  ItemsTemplateT,
  CustomerT,
  TagT,
  PartnerT
} from 'shared/types/model'
import { createAction } from '@reduxjs/toolkit'

export const receiveRoomTypes =
  createAction<DictT<RoomTypeT>>('RECEIVE_ROOM_TYPES')

export const clear = createAction('CLEAR')
export const logout = createAction('LOGOUT')
export const receiveAuthData = createAction<{
  uid: string
  email: string | null
} | null>('RECEIVE_AUTH_DATA')

export const receiveDesigners =
  createAction<DictT<DesignerT>>('RECEIVE_DESIGNERS')

export const receiveItems = createAction<DictT<ItemT>>('RECEIVE_ITEMS')

export const receiveRequestsDesign = createAction<DictT<RequestDesignT>>(
  'RECEIVE_REQUESTS_DESIGN'
)

export const receiveSettings = createAction<SettingsT>('RECEIVE_SETTINGS')
export const receiveSlots = createAction<DictT<SlotT>>('RECEIVE_SLOTS')
export const receiveSuppliers =
  createAction<DictT<SupplierT>>('RECEIVE_SUPPLIERS')
export const receiveTourModels = createAction<DictT<TourModelT> | null>(
  'RECEIVE_TOUR_MODELS'
)

export const receiveTemplates =
  createAction<DictT<ItemsTemplateT>>('RECEIVE_TEMPLATES')

export const addMattertag = createAction<{ tourSlotId: string; tagId: string }>(
  'ADD_MATTERTAG'
)

export const removeMattertag = createAction<{ tourSlotId: string }>(
  'REMOVE_MATTERTAG'
)

export const resetMattertags = createAction('RESET_MATTERTAGS')

export const receiveCustomers =
  createAction<DictT<CustomerT>>('RECEIVE_CUSTOMERS')

export const receiveTags = createAction<DictT<TagT>>('RECEIVE_TAGS')
export const receivePartners = createAction<DictT<PartnerT>>('RECEIVE_PARTNERS')
