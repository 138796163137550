import { useMemo, FC } from 'react'
import _ from 'lodash'
import { Select, OptionBase, GroupBase, SingleValue } from 'chakra-react-select'
import { useSelector } from 'model/hooks'
import { SlotT } from 'shared/types/model'

interface OptionT extends OptionBase {
  label: string
  value: string
}

type Props = {
  onChange: (slotId: string) => void
  value: string
}

const SlotSwitch: FC<Props> = ({ value, onChange }) => {
  const slots = useSelector(state => state.slots)
  const options = useMemo(
    () =>
      _.map(slots, (slot: SlotT) => ({ value: slot.id, label: slot.title })),
    [slots]
  )

  const valueOpt = useMemo(
    () =>
      ({
        value,
        label: _.get(slots, [value, 'title'])
      } as OptionT),
    [slots, value]
  )

  const onChangeTrades = (opt: SingleValue<OptionT>) => {
    if (opt) {
      onChange(opt?.value)
    }
  }

  return (
    <Select<OptionT, false, GroupBase<OptionT>>
      closeMenuOnSelect={false}
      selectedOptionStyle='check'
      hideSelectedOptions={false}
      value={valueOpt}
      options={options}
      placeholder='Select states'
      onChange={v => onChangeTrades(v)}
      chakraStyles={{
        menu: provided => ({ ...provided, zIndex: 1000 }),
        container: provided => ({ ...provided, bgColor: 'white' })
      }}
    />
  )
}

export default SlotSwitch
