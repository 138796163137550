import { FC, useMemo, useEffect, useState } from 'react'
import _ from 'lodash'

import { ModelT } from 'shared/types/model'
import { MpSdk } from 'shared/bundle/sdk'
// import MPBox from 'shared/components/Box'
import BoxSimple from 'shared/components/BoxSimple'

// import DirectionalLight from 'shared/components/DirectionalLight'
import Item from 'shared/components/Item'
import { updateItemField } from 'controllers/items'
import store from 'model/store'

import { Vector3Tuple, Vector3 } from 'three'

type Props = {
  itemId: string
  model: ModelT
  slotSize: Vector3Tuple
  sdk: MpSdk
  slotPosition: Vector3
  showBox: boolean
  boundingBox: Vector3 | undefined
  setBoundingBox: (boundingBox: Vector3 | undefined) => void
}

const ItemPreview: FC<Props> = ({
  itemId,
  model,
  slotSize,
  sdk,
  slotPosition,
  showBox = true,
  boundingBox,
  setBoundingBox
}) => {
  const [sceneObject, setSceneObject] = useState<MpSdk.Scene.IObject | null>(
    null
  )

  useEffect(() => {
    const run = async () => {
      const [so]: Array<MpSdk.Scene.IObject> = await sdk.Scene.createObjects(1)
      setSceneObject(so)
    }
    run()
    return () => sceneObject?.stop()
  }, [])

  const offsetV3 = useMemo(() => {
    const offset = _.get(model, 'offset', [0, 0, 0])
    const v3 = new Vector3()
    v3.fromArray(offset)
    return v3
  }, [model.offset])

  const onMove = (toPos: Vector3) => {
    // console.log('onMove', toPos)
    const newOffsetV3 = toPos.clone().add(slotPosition.clone().negate())
    // console.log('newOffsetV3', newOffsetV3)
    const newOffset = _.map(newOffsetV3.toArray(), v => _.round(v, 3))
    // console.log('newOffset', newOffset)
    // const offset = _.map(toPos, p => _.round(p, 3))
    const state = store.getState()
    const curOffset = _.get(
      state,
      ['items', itemId, 'model', 'offset'],
      [0, 0, 0]
    )
    // console.log('curOffset', curOffset)
    if (!_.isEqual(curOffset, newOffset)) {
      updateItemField(itemId, 'model.offset', newOffset)
    } else {
      // console.log('%cSKIP update', 'color:red;')
    }
  }

  // const itemPosV3 = useMemo(() => {
  //   return slotPosition.clone().add(offsetV3)
  // }, [slotPosition, offsetV3])

  if (_.isNil(sceneObject)) {
    return null
  } else {
    return (
      <>
        <Item
          key={itemId}
          id='item'
          sdk={sdk}
          url={model.glb}
          scale={model.scale}
          onMove={onMove}
          position={slotPosition}
          offset={offsetV3}
          rotation={model.rotation}
          sceneObject={sceneObject}
          boundingBox={boundingBox}
          setBoundingBox={setBoundingBox}
          isSelected
          viewMode='mode.inside'
        />
        {showBox && (
          <BoxSimple
            sdk={sdk}
            id={'box'}
            size={slotSize}
            position={slotPosition}
            showFrontSide
            sceneObject={sceneObject}
            // rotation={[0, 0, 0]}
            itemOffset={offsetV3}
            visible
            canMove={false}
            showFloorLines
          />
        )}
      </>
    )
  }
}

export default ItemPreview
