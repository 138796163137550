import { Flex } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import DesignersContent from 'pages/designers/DesignersContent'

const Designers = () => {
  return (
    <Flex direction='column' w='full' h='full'>
      <NavBar
        title={'Designers'}
        subheading={
          'Interior Designers that can handle customers design requests'
        }
      />
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <DesignersContent />
      </Flex>
    </Flex>
  )
}

export default Designers
