import { FC } from 'react'
import { Provider } from 'react-redux'
import { ChakraProvider, theme } from '@chakra-ui/react'
import 'react-base-table/styles.css'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import AppRouter from 'navigation/AppRouter'
import store from 'model/store'

const App: FC<{}> = () => {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AppRouter />
      </ChakraProvider>
    </Provider>
  )
}

export default App
