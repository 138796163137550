import { Flex } from '@chakra-ui/react'
import RoomTypeList from 'pages/templates/RoomTypeList'
import TemplateEditor from 'pages/templates/TemplateEditor'

const Templates = () => {
  return (
    <Flex direction='column' w='full' h='full'>
      <Flex
        direction={'row'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <RoomTypeList />
        <TemplateEditor />
      </Flex>
    </Flex>
  )
}

export default Templates
