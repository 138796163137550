import { FC, useMemo, ReactNode } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Menu,
  MenuButton,
  Avatar,
  VStack,
  Text,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faUser } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  title: ReactNode
  user: {
    name: string
    email: string
  } | null
  signOut: () => void
  children?: ReactNode
  subheading?: string
}

const NavBar: FC<Props> = ({ title, user, signOut, children, subheading }) => {
  const menu = useMemo(() => {
    if (user) {
      return (
        <Menu>
          <MenuButton fontSize={'1.4em'}>
            <FontAwesomeIcon icon={faUser} size='1x' />
          </MenuButton>
          <MenuList>
            <Flex direction='row' align={'center'} gap='2' px={3}>
              <Avatar bg={'teal'} color='white' size={'sm'} name={user.name} />
              <VStack align={'flex-start'} spacing={0}>
                <Text fontWeight={600}>{user.name}</Text>
                <Text fontSize={'sm'} color='gray.500'>
                  {user.email}
                </Text>
              </VStack>
            </Flex>
            <MenuDivider />
            <MenuItem
              icon={<FontAwesomeIcon icon={faPowerOff} />}
              onClick={signOut}
              px={6}
            >
              Sign Out
            </MenuItem>
          </MenuList>
        </Menu>
      )
    }
  }, [user])

  const renderTitle = () => {
    if (_.isString(title)) {
      return (
        <VStack align='flex-start' spacing={0}>
          <Heading size='md'>{title}</Heading>
          {subheading && <Text fontSize='xs'>{subheading}</Text>}
        </VStack>
      )
    } else {
      return title
    }
  }

  return (
    <Flex
      borderBottomWidth={1}
      bg='white'
      h='16'
      align='center'
      px={4}
      w='full'
      justify={'space-between'}
    >
      {renderTitle()}
      <HStack spacing={4}>
        {children}
        {menu}
      </HStack>
    </Flex>
  )
}

export default NavBar
