import { onSnapshot, query, collection, setDoc, doc } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { PartnerT } from 'shared/types/model'
import { receivePartners } from 'model/actions'

export const fetchPartners = () => {
  try {
    const q = query(collection(db, 'partners'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receivePartners(res))
      },
      err => {
        console.log(`fetch partners error: ${err}`)
      }
    )
    addListener('partners', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchPartners error', e)
  }
}

export const dbCreatePartner = async (partner: Partial<PartnerT>) => {
  try {
    if (partner.id) {
      await setDoc(doc(db, 'partners', partner.id), _.omitBy(partner, _.isUndefined), { merge: true })
    }
  } catch (e) {
    Sentry.captureException(e)
    console.log('dbCreatePartner error', e)
  }
}
