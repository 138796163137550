import { createReducer } from '@reduxjs/toolkit'
import { DictT, SlotT } from 'types/model'
import { clear, logout, receiveSlots } from 'model/actions'

const initialState = {} as DictT<SlotT>

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveSlots, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
