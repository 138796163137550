import { onSnapshot, collection, query, where } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { CustomerT } from 'shared/types/model'
import { receiveCustomers } from 'model/actions'

export const fetchCustomers = () => {
  try {
    const q = query(
      collection(db, 'customers'),
      where('stripeCustomerId', '!=', null)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data() as CustomerT
          _.set(res, doc.id, { ...p, id: doc.id })
        })
        store.dispatch(receiveCustomers(res))
      },
      err => {
        console.log(`fetchCustomers error: ${err}`)
      }
    )
    addListener('customers', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchCustomers error', e)
  }
}
