// import { useRef } from 'react'
import { Flex } from '@chakra-ui/react'
import TourModelsContent from 'pages/tourModels/TourModelsContent'
import NavBar from 'components/NavBar'
// import CreateTourModal, { ICreateTourModal } from 'modals/CreateTourModal'
// import { faPlus } from '@fortawesome/pro-regular-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { IconProp } from '@fortawesome/fontawesome-svg-core'

const TourModels = () => {
  //   const createTourRef = useRef<ICreateTourModal>(null)

  // const navBarButton = (
  //   <Button
  //     colorScheme={'teal'}
  //     leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
  //     onClick={() => createTourRef.current?.open()}
  //     size='sm'
  //   >
  //     Add tour
  //   </Button>
  // )

  return (
    <Flex direction='column' w='full' h='full'>
      <NavBar title={'Matterport tour models'} />
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <TourModelsContent />
      </Flex>
      {/* <CreateTourModal ref={createTourRef} /> */}
    </Flex>
  )
}

export default TourModels
