import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState,
  useMemo
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Image,
  VStack,
  Center
} from '@chakra-ui/react'
import FilesPickerButton from 'shared/components/FilesPickerButton'
import { FileT } from 'shared/types/model'
import { useSelector } from 'model/hooks'
import { getAddress } from 'shared/utils/addressUtils'
import Resizer from 'react-image-file-resizer'
import { updateTourModelField } from 'controllers/tours'
import { noImageUrl } from 'shared/constants/helpers'
import storage from 'controllers/storage'
import { generateId } from 'controllers/db'

export interface ITourModelPhotoModal {
  open: (tourModelId: string) => void
}

type Props = {}

const TourModelPhotoModal: ForwardRefRenderFunction<
  ITourModelPhotoModal,
  Props
> = (_props: Props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const tourModels = useSelector(state => state.tourModels)
  const [tmId, setTmId] = useState<string | undefined>()

  const tourModel = useMemo(() => {
    if (tmId) return _.get(tourModels, tmId)
  }, [tourModels, tmId])

  useImperativeHandle(ref, () => ({
    open: (tourModelId: string) => {
      setTmId(tourModelId)
      onOpen()
    }
  }))

  const onPhotoUploaded = (files: FileT[]) => {
    if (tourModel) {
      console.log('on3dFileUploaded', files)
      const f = files[0]
      updateTourModelField(tourModel.id, 'photoUrl', f.url)
    }
  }

  const resizeFile = async (file: File): Promise<File> =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'JPEG',
        100,
        0,
        f => resolve(f as File),
        'file'
      )
    })

  const renderModalBody = () => {
    if (tourModel) {
      return (
        <VStack spacing={4}>
          <Text>
            Address:{' '}
            <b>{tourModel.address ? getAddress(tourModel.address) : ''}</b>
          </Text>
          <Center boxSize={'400px'} rounded='base' borderWidth={1}>
            <Image
              boxSize='400px'
              objectFit='cover'
              rounded='base'
              src={tourModel.photoUrl || noImageUrl}
              alt='tour_photo'
            />
          </Center>
          <FilesPickerButton
            userId='admin'
            storagePath={`/tourModels/${tmId}`}
            onComplete={onPhotoUploaded}
            buttonTitle={
              _.has(tourModel, 'photoUrl')
                ? 'Replace tour photo'
                : 'Upload tour photo'
            }
            inputProps={{
              multiple: false,
              accept: 'image/*'
            }}
            fileProcessor={resizeFile}
            generateId={generateId}
            storage={storage}
          />
        </VStack>
      )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={1}>Tour photo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {renderModalBody()}
          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              colorScheme={'gray'}
              ml={2}
              variant='outline'
              onClick={onClose}
            >
              Close
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(TourModelPhotoModal)
