import {
  onSnapshot,
  query,
  collection,
  doc,
  updateDoc,
  where
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { DictT, RequestDesignT } from 'shared/types/model'
import { receiveRequestsDesign } from 'model/actions'

export const fetchRequestsDesign = () => {
  try {
    const q = query(
      collection(db, 'requestsDesign'),
      where('stripeInvoice', '!=', null)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<RequestDesignT> = {}
        sn.forEach(doc => {
          const p = doc.data() as RequestDesignT
          _.set(res, doc.id, p)
        })
        console.log('receive requests design', res)
        store.dispatch(receiveRequestsDesign(res))
      },
      err => {
        console.log(`receive requests error: ${err}`)
      }
    )
    addListener('requestsDesign', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('receive requests error', e)
  }
}

export const dbUpdateRequestDesign = async (
  rdId: string,
  upd: Partial<RequestDesignT>
) => {
  try {
    console.log('dbUpdateRequestDesign', upd)
    const ref = doc(db, `requestsDesign/${rdId}`)
    await updateDoc(ref, _.omitBy(upd, _.isUndefined))
  } catch (e) {
    Sentry.captureException(e)
  }
}
