import _ from 'lodash'
import { Flex, Text, useColorModeValue, VStack, HStack } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/logo_only.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faHouseLaptop,
  faUsers,
  faChair,
  faTruck,
  faPersonCircleQuestion,
  faGrate,
  faTags,
  faHandshake
} from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'

interface LinkItemT {
  name: string
  pth: string
  icon: IconDefinition
}
const linkItems: LinkItemT[] = [
  { name: 'Items', pth: '/items', icon: faChair as IconDefinition },
  { name: 'Tours', pth: '/models', icon: faHouseLaptop as IconDefinition },
  { name: 'Suppliers', pth: '/suppliers', icon: faTruck as IconDefinition },
  { name: 'Designers', pth: '/designers', icon: faUsers as IconDefinition },
  {
    name: 'Requests',
    pth: '/requests',
    icon: faPersonCircleQuestion as IconDefinition
  },
  { name: 'Templates', pth: '/templates', icon: faGrate as IconDefinition },
  { name: 'Tags', pth: '/tags', icon: faTags as IconDefinition },
  { name: 'Partners', pth: '/partners', icon: faHandshake as IconDefinition }
  // { name: 'Settings', pth: '/settings', icon: faGear as IconDefinition }
]

const DashboardSidebar = () => {
  const navigate = useNavigate()

  const renderNavItem = (item: LinkItemT) => {
    const pathname = window.location.pathname
    const isActive = _.startsWith(pathname, item.pth)
    return (
      <Flex
        key={item.name}
        align='center'
        px='4'
        py='2'
        borderRadius='lg'
        role='group'
        w='full'
        cursor={isActive ? undefined : 'pointer'}
        _hover={
          isActive
            ? undefined
            : {
                bg: 'cyan.400',
                color: 'white'
              }
        }
        bg={isActive ? 'cyan.400' : undefined}
        as={isActive ? undefined : 'button'}
        onClick={isActive ? undefined : () => navigate(item.pth)}
        color={isActive ? 'white' : undefined}
      >
        {item.icon && <FontAwesomeIcon icon={item.icon} fixedWidth />}
        <Text fontWeight={500} pl={2}>
          {item.name}
        </Text>
      </Flex>
    )
  }

  return (
    <Flex
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      px={4}
      h='full'
      direction='column'
    >
      <HStack
        pt={2}
        alignItems='center'
        justifyContent='flex-start'
        spacing={2}
      >
        <Logo height='46px' width='46px' />
        <VStack align='flex-start' spacing={-1}>
          <Text fontSize={'2xl'} fontWeight='800' color='teal.900'>
            UpStager
          </Text>
          <Text fontSize={'sm'} color='gray.500' letterSpacing={1.2}>
            Admin panel
          </Text>
        </VStack>
      </HStack>
      <VStack spacing={1} flex={1} pt={8}>
        {linkItems.map(renderNavItem)}
      </VStack>
    </Flex>
  )
}

export default DashboardSidebar
