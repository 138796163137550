import { createReducer } from '@reduxjs/toolkit'
import { DictT, PartnerT } from 'types/model'
import { clear, logout, receivePartners } from 'model/actions'

const initialState = {} as DictT<PartnerT>

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receivePartners, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default reducer
