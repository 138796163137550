import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState,
  useMemo
} from 'react'
import _ from 'lodash'
import numeral from 'numeral'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  FormHelperText,
  VStack,
  Image
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { SlotT, FileT } from 'shared/types/model'
import { createSlot, dbDeleteSlot, updateSlot } from 'controllers/slots'
import { useSelector } from 'model/hooks'
import { Vector3Tuple } from 'three'
import {
  convertMetersToInches,
  convertInchesToMeters
} from 'shared/utils/unitsConvert'
import FilesPickerButton from 'shared/components/FilesPickerButton'
import storage from 'controllers/storage'

export interface IAddSlotModal {
  open: (slotId?: string) => void
}

type Props = {}

const AddSlotModal: ForwardRefRenderFunction<IAddSlotModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const slots = useSelector(state => state.slots)
  const items = useSelector(state => state.items)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [photo, setPhoto] = useState<FileT | undefined>()
  const [length, setLength] = useState('')
  const [sId, setSId] = useState<string | undefined>()

  const itemsGroups = useMemo(() => _.groupBy(items, 'kind'), [items])

  useImperativeHandle(ref, () => ({
    open: (slotId?: string) => {
      setSId(slotId)
      if (slotId) {
        const s = _.get(slots, slotId)
        const size = _.map(
          _.get(s, 'size'),
          num => (_.round(convertMetersToInches(num), 2) * 100) / 100
        )
        if (s) {
          setWidth(_.toString(size[0]))
          setHeight(_.toString(size[1]))
          setLength(_.toString(size[2]))
          setName(s.title)
          setPhoto(s.photo)
        }
      }
      onOpen()
    }
  }))

  const slot = useMemo(() => {
    if (slots && sId) {
      return slots[sId]
    } else {
      return null
    }
  }, [slots, sId])

  const renderNumberInput = (
    value: string,
    onChange: (v: string) => void,
    label: string
  ) => {
    return (
      <VStack align={'flex-start'}>
        <FormHelperText>{label}</FormHelperText>
        <NumberInput
          min={0.1}
          step={1}
          precision={2}
          value={_.isNaN(value) ? 0 : value}
          onChange={(strV: string) => onChange(strV)}
        >
          <NumberInputField id='amount' />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>
    )
  }

  const isSet = (v: string) => {
    const num = numeral(v).value()
    return !_.isNil(num)
  }

  const canCreate = () => {
    return isSet(width) && isSet(height) && isSet(length) && !_.isEmpty(name)
  }

  const toNum = (s: string) => {
    const n = numeral(s).value()
    if (_.isNil(n)) {
      return 0
    } else {
      return convertInchesToMeters(n)
    }
  }

  const onCreateClick = () => {
    if (sId) {
      const size: Vector3Tuple = [toNum(width), toNum(height), toNum(length)]
      const upd: Partial<SlotT> = {
        title: name,
        size,
        displayOrder: 0
      }
      if (photo) {
        upd.photo = photo
      }
      updateSlot(sId, upd)
    } else {
      const id = generateId()
      const slot: SlotT = {
        id,
        title: name,
        size: [toNum(width), toNum(height), toNum(length)],
        createdAt: _.now()
      }
      if (photo) {
        slot.photo = photo
      }
      createSlot(slot)
    }
    onClose()
  }

  const onPhotosUploaded = (files: FileT[]) => {
    if (_.size(files) > 0) {
      const f = files[0]
      setPhoto(f)
    }
  }

  const onDeleteSlot = () => {
    if (slot) {
      dbDeleteSlot(slot.id)
      onClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {sId ? 'Edit catalog category' : 'Create catalog category'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor='slotName'>Name</FormLabel>
            <Input
              value={name}
              placeholder='Office chairs'
              onChange={e => setName(e.target.value)}
            />
          </FormControl>
          <FormControl pt={4}>
            <FormLabel htmlFor='slotDimensions'>Dimensions</FormLabel>
            <HStack>
              {renderNumberInput(width, setWidth, 'width')}
              {renderNumberInput(height, setHeight, 'height')}
              {renderNumberInput(length, setLength, 'length')}
            </HStack>
          </FormControl>
          <VStack pt={8}>
            <Image
              boxSize={200}
              src={_.get(photo, 'thumbnailUrl', _.get(photo, 'url'))}
              alt={photo?.name || ''}
            />
            <FilesPickerButton
              userId='admin'
              storagePath='/slots'
              onComplete={onPhotosUploaded}
              buttonTitle='Upload catalog photo'
              thumbnailSize={300}
              inputProps={{
                accept: 'image/*',
                multiple: false
              }}
              storage={storage}
              generateId={generateId}
            />
          </VStack>
          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              isDisabled={!slot || !_.isEmpty(itemsGroups[slot.id])}
              colorScheme='red'
              onClick={onDeleteSlot}
            >
              Delete category
            </Button>
            <Button
              colorScheme={'green'}
              ml={2}
              disabled={!canCreate()}
              onClick={onCreateClick}
            >
              {_.isNil(sId) ? 'Create' : 'Update'}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddSlotModal)
