import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { TagT } from 'shared/types/model'
import { dbCreateTag, dbDeleteTag, dbUpdateTag } from 'controllers/tags'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
export interface IAddTagModal {
  open: (t?: TagT) => void
}

type Props = {}

const AddTagModal: ForwardRefRenderFunction<IAddTagModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [tagId, setTagId] = useState<string | null>(null)

  useImperativeHandle(ref, () => ({
    open: (t?: TagT) => {
      setTagId(t ? t.id : null)
      setName(t ? t.name : '')
      onOpen()
    }
  }))

  const canCreate = () => {
    return !_.isEmpty(name)
  }

  const close = () => {
    setName('')
    setTagId(null)
    onClose()
  }

  const onCreateClick = () => {
    if (tagId) {
      dbUpdateTag(tagId, { name })
    } else {
      const id = generateId()
      const s: TagT = {
        id,
        name
      }
      console.log('create tag', s)
      dbCreateTag(s)
    }
    close()
  }

  const onDeleteClick = (tagId: string) => {
    dbDeleteTag(tagId)
    close()
  }

  const renderDeleteButton = () => {
    if (tagId) {
      return (
        <Button
          colorScheme={'red'}
          leftIcon={<FontAwesomeIcon icon={faTrash} />}
          onClick={() => onDeleteClick(tagId)}
        >
          Delete
        </Button>
      )
    } else {
      return <Flex />
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{!_.isNil(tagId) ? 'Edit tag' : 'Add tag'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor='tag name'>Name</FormLabel>
            <Input
              value={name}
              placeholder='tag name'
              onChange={e => setName(e.target.value)}
            />
          </FormControl>
          <Flex mb={4} pt={12} justify='space-between'>
            {renderDeleteButton()}
            <Button
              colorScheme={'teal'}
              ml={2}
              disabled={!canCreate()}
              onClick={onCreateClick}
            >
              {tagId ? 'Update' : 'Add'}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddTagModal)
