import {
  onSnapshot,
  collection,
  query,
  where,
  updateDoc,
  doc
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { DesignerT } from 'shared/types/model'
import { receiveDesigners } from 'model/actions'

export const fetchDesigners = () => {
  try {
    const q = query(
      collection(db, 'designers'),
      where('requestAccess', '>=', 0)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data() as DesignerT
          _.set(res, doc.id, { ...p, id: doc.id })
        })
        store.dispatch(receiveDesigners(res))
      },
      err => {
        console.log(`fetchDesigners error: ${err}`)
      }
    )
    addListener('designers', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchDesigners error', e)
  }
}

export const dbUpdateDesigner = (
  designerId: string,
  update: Partial<DesignerT>
) => {
  try {
    updateDoc(doc(db, 'designers', designerId), update)
  } catch (e) {
    Sentry.captureException(e)
    console.log('dbUpdateDesigner error', e)
  }
}
