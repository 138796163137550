import { Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import DashboardSidebar from 'pages/dashboard/DashboardSidebar'

const DashboardWrapper = () => {
  return (
    <Flex minH='100vh' h='full' bg={'gray.100'} direction='row'>
      <DashboardSidebar />
      <Outlet />
    </Flex>
  )
}

export default DashboardWrapper
