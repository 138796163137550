import { useParams } from 'react-router'
import { useTitle } from 'react-use'
import _ from 'lodash'
import { Flex, Text } from '@chakra-ui/react'

import TourView from 'shared/components/TourView'
import { useDispatch, useSelector } from 'model/hooks'
import Loading from 'pages/Loading'
import {
  dbUpdateTemplatePath,
  dbDeleteTemplateSlot
} from 'controllers/templates'
import { generateId } from 'controllers/db'
import { AddItemT, TourSlotT, ItemT } from 'shared/types/model'
import { getItemsBySlot } from 'model/selectors/itemsSelector'
import EditTourNavBar from 'pages/templates/EditTourNavBar'
import UserItemsPanel from 'pages/templates/UserItemsPanel'
import { removeMattertag, addMattertag, resetMattertags } from 'model/actions'
import { useEffect } from 'react'
import { getRoomTypesWithTemplates } from 'model/selectors/templatesSelector'

const TemplateEditor = () => {
  const { templateId } = useParams()
  const items = useSelector(state => state.items)
  const slots = useSelector(state => state.slots)
  const dispatch = useDispatch()
  const mattertags = useSelector(state => state.mattertags)
  const suppliers = useSelector(state => state.suppliers)

  const templates = useSelector(state => state.templates)
  const template = templateId && templates[templateId]
  const itemsBySlot = useSelector(getItemsBySlot)
  const roomTypesWithTemplates = useSelector(getRoomTypesWithTemplates)

  useTitle(`${template ? template.name : ''} - UpStager Customer`)

  useEffect(() => {
    dispatch(resetMattertags())
  }, [templateId])

  const deleteSlot = (slotId: string) => {
    if (templateId) {
      dbDeleteTemplateSlot(templateId, slotId)
      dispatch(removeMattertag({ tourSlotId: slotId }))
    }
  }

  const updateTourPath = async (pth: string, value: any) => {
    if (templateId) {
      await dbUpdateTemplatePath(templateId, pth, value)
    }
  }

  const updateTourSlot = async (
    slotId: string,
    key: keyof TourSlotT,
    value: any
  ) => {
    updateTourPath(`slots.${slotId}.${key}`, value)
  }

  const onResetDesign = async () => {
    await updateTourPath('slots', {})
  }

  const addItems = (aItems: AddItemT[]) => {
    _.forEach(aItems, ({ itemId, position, rotation }: AddItemT) => {
      const id = generateId()
      const ts: TourSlotT = {
        id,
        itemId,
        position,
        createdAt: _.now(),
        rotation
      }
      updateTourPath(`slots.${ts.id}`, ts)
    })
  }

  const onMattertagCreated = (tourSlotId: string, mtId: string) => {
    console.log('onMattertagCreated', tourSlotId, mtId)
    dispatch(addMattertag({ tourSlotId, tagId: mtId }))
    // dispatch(receiveMattertag(tourSlotId, mtId))
  }

  const getItems = async (slotId: string, amount: number, offset: number) => {
    const allItems = _.get(itemsBySlot, slotId, {})
    const enabledItems = _.filter(
      allItems,
      (item: ItemT) =>
        _.get(item, 'enabled', false) &&
        !_.isNil(item.displayOrder) &&
        (item.displayOrder || 0) > offset
    )
    const sortedItems = _.orderBy(enabledItems, 'displayOrder', 'asc')
    return _.take(sortedItems, amount)
  }

  if (template) {
    return (
      <TourView
        tourSlots={template.slots}
        modelId={'saPpcn1h9gE'}
        tourId={templateId}
        tourModelId={templateId}
        deleteSlot={deleteSlot}
        updateTourSlot={updateTourSlot}
        onResetDesign={onResetDesign}
        addItems={addItems}
        // itemsBySlot={itemsBySlot}
        items={items}
        slots={slots}
        mattertags={mattertags}
        suppliers={suppliers}
        onMattertagCreated={onMattertagCreated}
        EditTourNavBar={EditTourNavBar}
        UserItemsPanel={UserItemsPanel}
        designName={template.name}
        roomTypesWithTemplates={roomTypesWithTemplates}
        getItems={getItems}
        loadItems={() => null}
        canEdit
        pricesVisible
      />
    )
  } else if (templateId) {
    return <Loading />
  } else {
    return (
      <Flex w='full' h='full' align='center' justify={'center'}>
        <Text>Select a template</Text>
      </Flex>
    )
  }
}

export default TemplateEditor
