import { useState, FC, useMemo, useEffect, useRef } from 'react'
import { Box, Checkbox, Flex, HStack, IconButton } from '@chakra-ui/react'
import _ from 'lodash'
import { Vector3, Vector3Tuple } from 'three'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useSelector } from 'model/hooks'
import ItemPreview from 'components/item/ItemPreview'
import { SlotT, ItemT } from 'shared/types/model'
// import Menu from 'pages/itemsEditor/Menu'
import ItemPanel from 'pages/itemsEditor/ItemPanel'
// import ItemPanelEmpty from 'pages/itemsEditor/ItemPanelEmpty'
import { MpSdk } from 'shared/bundle/sdk'
import Tour from 'shared/components/Tour'
import config from 'shared/config'
import RoomShadow from 'shared/components/RoomShadow'
import AmbientLight from 'shared/components/AmbientLight'
import PointLightOnCamera from 'shared/components/PointLightOnCamera'
import AdminCatalog from 'components/catalogPanel/AdminCatalog'
import AdminCategoryItems from 'components/catalogPanel/AdminCategoryItems'
import { getItemsBySlot } from 'model/selectors/itemsSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ProductCardModal, {
  IProductCardModal
} from 'shared/components/ProductCardModal'
import AddItemModal, { IAddItemModal } from 'modals/AddItemModal'

// const tId = 'vycyVs9Xe9W'
// const tourId = 'Y6eHowL4taa'

const Dashboard: FC<{}> = () => {
  const [searchParams] = useSearchParams()
  const [selectedItemId, setSelectedItem] = useState<string | undefined>()
  const [showBox, setShowBox] = useState<boolean>(true)
  const [sdk, setSdk] = useState<MpSdk | null>(null)
  const [boundingBox, setBoundingBox] = useState<Vector3 | undefined>()
  const slots = useSelector(state => state.slots)
  const suppliers = useSelector(state => state.suppliers)
  const itemsBySlot = useSelector(getItemsBySlot)
  const selectedItem = useSelector(state =>
    _.get(state.items, selectedItemId || '')
  )
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const navigate = useNavigate()
  const productCardRef = useRef<IProductCardModal>(null)
  const addItemModalRef = useRef<IAddItemModal>(null)

  useEffect(() => {
    setBoundingBox(undefined)
  }, [selectedItemId])

  const onSdkReady = (sdk: MpSdk) => {
    setSdk(sdk)
  }

  // let tourId = 'j4RZx7ZGM6T'
  let tourId = 'Y6eHowL4taa'

  const tInUrl = searchParams.get('m')
  if (tInUrl) tourId = tInUrl

  // console.log('%cTOUR_ID: ', 'color: gold;', tourId)

  const initialPosition = useMemo(() => {
    return new Vector3(6.3, 0.01, 3.3)
    // return new Vector3(-0.5, 0, -0.5)
  }, [])

  const toggleBox = () => {
    setShowBox(!showBox)
  }
  const unselectItem = () => {
    setSelectedItem(undefined)
  }

  // const renderItemPanel = () => {
  //   if (selectedItem) {
  //     return (
  //       <ItemPanel
  //         item={selectedItem}
  //         key={selectedItemId}
  //         boundingBox={boundingBox}
  //         unselectItem={unselectItem}
  //       />
  //     )
  //   } else {
  //     return <ItemPanelEmpty />
  //   }
  // }

  const renderItemPreview = () => {
    const model = _.get(selectedItem, 'model')
    const kind = _.get(selectedItem, 'kind')
    console.log('renderItemPreview', model?.offset)
    if (kind) {
      const slot: SlotT = slots[kind]
      const slotSize: Vector3Tuple | undefined = _.get(slot, 'size')
      if (sdk && selectedItem && model && slotSize) {
        const glbUrl = _.get(selectedItem, 'model.glb', '')
        return (
          <ItemPreview
            key={selectedItem.id + glbUrl + slotSize.toString()}
            itemId={selectedItem.id}
            model={model}
            sdk={sdk}
            slotSize={slotSize}
            slotPosition={initialPosition}
            showBox={showBox}
            boundingBox={boundingBox}
            setBoundingBox={setBoundingBox}
          />
        )
      } else {
        return null
      }
    }
  }

  // const onCloseCatalogClick = () => {
  //   setSelectedCategory(null)
  // }

  // const getItems = async (slotId: string, amount: number, offset: number) => {
  //   console.log('getItems', slotId, amount, offset)
  //   return []
  // }

  const renderRightPanel = () => {
    if (selectedItemId && selectedItem) {
      return (
        <ItemPanel
          item={selectedItem}
          key={selectedItemId}
          boundingBox={boundingBox}
          unselectItem={unselectItem}
        />
      )
    } else if (selectedCategory) {
      console.log('Category Items')
      return (
        <AdminCategoryItems
          toCatalog={() => setSelectedCategory(null)}
          addToRoom={setSelectedItem}
          selectedItemId={selectedItemId || null}
          openProductCard={(item: ItemT) => productCardRef.current?.open(item)}
          slot={slots[selectedCategory]}
          items={itemsBySlot[selectedCategory]}
          // getItems={getItems}
          suppliers={suppliers}
          onAddItem={() =>
            selectedCategory && addItemModalRef.current?.open(selectedCategory)
          }
        />
      )
    } else {
      console.log('return Catalog')
      return (
        <AdminCatalog
          goBack={() => null}
          selectCategory={(slotId: string) => setSelectedCategory(slotId)}
          selectedItemId={selectedItemId || null}
          slots={slots}
        />
      )
    }
  }

  return (
    <Flex w='full' h='full' direction='row'>
      {/* <Menu
        selectedItemId={selectedItemId || undefined}
        onSelectItem={(itemId: string) => setSelectedItem(itemId)}
      />
      <Flex
        bg='gray.50'
        w='md'
        h='full'
        borderLeftWidth={1}
        borderRightWidth={1}
        shrink={0}
        grow={0}
      >
        {renderItemPanel()}
      </Flex> */}
      <Box h='full' w='full'>
        <Tour
          applicationKey={config.applicationKey}
          modelId={tourId}
          onSdkReady={onSdkReady}
          hideDollHouse
          hideGuidedTour
          hideHighlightReels
          hideFloorSwitch
          hideVR
          // disableZoom
        />
        {sdk && <PointLightOnCamera sdk={sdk} />}
        {sdk && <RoomShadow sdk={sdk} />}
        {sdk && <AmbientLight sdk={sdk} />}

        {renderItemPreview()}
      </Box>
      <Flex
        position='absolute'
        top={0}
        left='0'
        // m='2'
        // bg='blackAlpha.600'
        p='4'
        h='48px'
        align='center'
        rounded='lg'
      >
        <HStack py={2} spacing={4}>
          <IconButton
            size='sm'
            aria-label='back'
            icon={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
            onClick={() => navigate('/requests')}
          />
          <Checkbox
            colorScheme='gray'
            isChecked={showBox}
            onChange={toggleBox}
            color='white'
            borderColor='white'
          >
            Show box
          </Checkbox>
        </HStack>
      </Flex>
      {renderRightPanel()}
      <AddItemModal
        ref={addItemModalRef}
        onSelectItem={(itemId: string) => setSelectedItem(itemId)}
      />
      <ProductCardModal ref={productCardRef} />
    </Flex>
  )
}

export default Dashboard
