import { FC, useEffect, useRef } from 'react'
import _ from 'lodash'
import { MpSdk, Mode } from 'shared/bundle/sdk'
import { DictT } from 'shared/types/model'
import { Vector3 } from 'three'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { customTransformControlsType } from 'shared/components/matterport/MTransformControls'

type Props = {
  sdk: MpSdk
  position?: Vector3
  onMove?: (toPosition: Vector3) => void
  sceneObject: MpSdk.Scene.IObject
  viewMode: Mode.Mode
}

const ItemsSet: FC<Props> = ({
  sdk,
  position = new Vector3(0, 0, 0),
  onMove = () => null,
  sceneObject,
  viewMode
}) => {
  const nodesRef = useRef<DictT<MpSdk.Scene.INode>>({})
  const spiesRef = useRef<DictT<MpSdk.ISubscription>>({})
  const tcRef = useRef<TransformControls>(null)

  useEffect(() => {
    // console.log('useEffect viewMode', viewMode, isSelected)
    const transformNode = nodesRef.current.transformNode
    if (transformNode) {
      const comps = transformNode.componentIterator()
      for (const c of comps) {
        c.inputs.visible = true
        c.inputs.viewMode = viewMode
        c.inputs.showY = viewMode !== 'mode.floorplan'
      }
    }
  }, [viewMode])

  const onObjectChange = () => onMove(nodesRef.current.cNode.position.clone())

  const addTransformControl = (node: MpSdk.Scene.INode) => {
    // if (isSelected && viewMode !== 'mode.floorplan') {
    console.log('%cadd transform controls', 'color: green;', node.obj3D)
    const transformNode = sceneObject.addNode()
    nodesRef.current.transformNode = transformNode

    const transformComponent = transformNode.addComponent(
      customTransformControlsType,
      {
        selection: node.obj3D,
        size: 0.8,
        visible: true,
        viewMode,
        showY: viewMode !== 'mode.floorplan'
        // visible: isAdded
      }
    )
    // console.log('transformComponent', transformComponent)
    transformNode.start()

    const tc: TransformControls = transformComponent.outputs
      .objectRoot as TransformControls
    // tc.addEventListener('mouseUp', timerStop)
    tcRef.current = tc
    tc.addEventListener('objectChange', onObjectChange)

    // tc.addEventListener('mouseDown', timerStart)
    // }
    spiesRef.current.pointer = sdk.Pointer.intersection.subscribe(function (
      intersectionData
    ) {
      const p = intersectionData.position
      transformComponent.inputs.cameraPosition = new Vector3(p.x, 30, p.z)
    })
    return tc
  }

  useEffect(() => {
    if (nodesRef.current.cNode) {
      nodesRef.current.cNode.position.copy(position)
    }
  }, [position])

  useEffect(() => {
    const run = async () => {
      const cNode = sceneObject.addNode('setCentralObject')
      cNode.addComponent('mp.centerPointer', {
        color: '#ffffff',
        opacity: 0.0001
      })
      nodesRef.current.cNode = cNode
      cNode.position.copy(position)
      cNode.start()
      addTransformControl(cNode)
    }
    run()
    return () => {
      _.forEach(nodesRef.current, n => n.stop())
      _.forEach(spiesRef.current, n => n.cancel())
      nodesRef.current = {}
      spiesRef.current = {}
      const tc = tcRef.current
      if (tc) {
        tc.removeEventListener('objectChange', onObjectChange)
        tcRef.current = null
      }
    }
  }, [])

  return null
}

export default ItemsSet
