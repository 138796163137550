import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState,
  useEffect
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Image
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { FileT, RoomTypeT } from 'shared/types/model'
import FilesPickerButton from 'shared/components/FilesPickerButton'
import storage from 'controllers/storage'
import { useSelector } from 'model/hooks'
import Resizer from 'react-image-file-resizer'
import { noImageUrl } from 'shared/constants/helpers'
import { dbCreateRoomType, dbUpdateRoomType } from 'controllers/roomTypes'

export interface IAddRoomType {
  open: (roomTypeId?: string) => void
}

type Props = {}

const AddRoomType: ForwardRefRenderFunction<IAddRoomType, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const roomTypes = useSelector(state => state.roomTypes)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [rtId, setRtId] = useState<string>()
  const [photo, setPhoto] = useState<string | undefined>()

  useImperativeHandle(ref, () => ({
    open: (rtId?: string) => {
      setRtId(rtId)
      setPhoto(undefined)
      onOpen()
    }
  }))

  useEffect(() => {
    setName(rtId && roomTypes ? _.get(roomTypes, [rtId, 'name'], '') : '')
    setPhoto(rtId && roomTypes ? _.get(roomTypes, [rtId, 'photo']) : undefined)
  }, [rtId, roomTypes])

  const canCreate = () => {
    return !_.isEmpty(name)
  }

  const onCreateClick = () => {
    if (rtId) {
      const upd: Partial<RoomTypeT> = {
        name,
        photo
      }
      dbUpdateRoomType(rtId, upd)
    } else {
      const rt: RoomTypeT = {
        id: generateId(),
        name,
        photo
      }
      dbCreateRoomType(rt)
    }
    onClose()
  }

  const onPhotosUploaded = (files: FileT[]) => {
    if (_.size(files) > 0) {
      const f = files[0]
      setPhoto(f.url)
    }
  }

  const resizeFile = async (file: File): Promise<File> =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'JPEG',
        100,
        0,
        f => resolve(f as File),
        'file'
      )
    })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      // closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{rtId ? 'Edit room type' : 'Add room type'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor='room type'>Name</FormLabel>
            <Input
              value={name}
              placeholder='Living room'
              onChange={e => setName(e.target.value)}
            />
          </FormControl>
          <VStack pt={8}>
            <Image boxSize={200} src={photo || noImageUrl} alt={'room photo'} />
            <FilesPickerButton
              userId='admin'
              storagePath='/roomTypes'
              onComplete={onPhotosUploaded}
              buttonTitle='Upload room photo'
              thumbnailSize={300}
              inputProps={{
                accept: 'image/*',
                multiple: false
              }}
              storage={storage}
              generateId={generateId}
              fileProcessor={resizeFile}
            />
          </VStack>
          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              // colorScheme={'blue'}
              variant={'outline'}
              ml={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme={'blue'}
              ml={2}
              disabled={!canCreate()}
              onClick={onCreateClick}
            >
              {_.isNil(rtId) ? 'Add' : 'Update'}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddRoomType)
