import { FC, useRef } from 'react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Text,
  VStack
} from '@chakra-ui/react'
import {
  faChevronDown,
  faEye,
  faTrashCan,
  faArrowUpFromArc
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import config from 'shared/config'

type Props = {
  onDelete?: () => void
  onSync?: () => void
  onView?: () => void
}

const ItemPanelMenu: FC<Props> = ({ onDelete, onSync, onView }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLButtonElement>(null)

  const deleteClicked = () => {
    onClose()
    if (onDelete) {
      onDelete()
    }
  }

  const renderDeleteDialog = () => {
    return (
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Item
            </AlertDialogHeader>

            <AlertDialogBody>
              <VStack spacing={0}>
                <Text color={'gray.600'}>Are you sure?</Text>
                <Text color={'gray.600'}>
                  The action will also delete it from all tours
                </Text>
                <Text color={'gray.600'}>
                  {"You can't undo this action afterwards."}
                </Text>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={deleteClicked} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )
  }

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
          colorScheme='blue'
          size='sm'
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem icon={<FontAwesomeIcon icon={faEye} />} onClick={onView}>
            View product card
          </MenuItem>
          {config.isDev && (
            <MenuItem
              icon={<FontAwesomeIcon icon={faArrowUpFromArc} />}
              onClick={onSync}
            >
              Sync to production
            </MenuItem>
          )}
          <MenuItem
            icon={<FontAwesomeIcon icon={faTrashCan} />}
            color='red.600'
            onClick={onOpen}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
      {renderDeleteDialog()}
    </>
  )
}

export default ItemPanelMenu
