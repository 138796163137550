import { useMemo, useEffect, useRef } from 'react'
import _ from 'lodash'
import {
  Flex,
  Text,
  Switch,
  Link,
  IconButton,
  useToast,
  Image,
  Box
} from '@chakra-ui/react'
import { useMeasure, useCopyToClipboard } from 'react-use'
import moment from 'moment'
import { useSelector } from 'model/hooks'
import BaseTable from 'react-base-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUpRightFromSquare,
  faUpRightFromSquare
} from '@fortawesome/pro-regular-svg-icons'

import { TourModelT } from 'shared/types/model'
import { updateTourModelField } from 'controllers/tours'
import config from 'shared/config'
import { noImageUrl } from 'shared/constants/helpers'
import TourModelPhotoModal, {
  ITourModelPhotoModal
} from 'modals/TourModelPhotoModal'

type RowT = {
  id: string
  street: string
  city: string
  state: string
  createdAt: number
  enabled: boolean
  description: string
  modelId: string
  photoUrl?: string
}

const TourModelsContent = () => {
  const tourModels = useSelector(state => state.tourModels)
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()
  const [state, copyToClipboard] = useCopyToClipboard()
  const toast = useToast()
  const tourModelPhotoModalRef = useRef<ITourModelPhotoModal>(null)

  useEffect(() => {
    if (state && state.value) {
      toast({
        title: 'Link copied to clipboard',
        description: 'Use the url to share the tour with customers',
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true
      })
    }
  }, [state])

  const toggleTour = (tourId: string, checked: boolean) => {
    updateTourModelField(tourId, 'enabled', checked)
  }

  const data = useMemo(() => {
    return _.map(tourModels, (p: TourModelT) => {
      return {
        id: p.id,
        street: _.get(p.address, 'name'),
        city: _.get(p.address, 'city'),
        state: _.get(p.address, 'state'),
        createdAt: p.createdAt,
        enabled: p.enabled,
        description: p.description,
        modelId: p.modelId,
        photoUrl: p.photoUrl || noImageUrl
      } as RowT
    })
  }, [tourModels])

  const columns = [
    {
      // flexGrow: 1,
      key: 'photo',
      dataKey: 'photoUrl',
      width: 80,
      title: 'Photo',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: string
        rowData: RowT
      }) => (
        <Box
          boxSize='40px'
          as='button'
          borderWidth={1}
          rounded={'base'}
          onClick={() => tourModelPhotoModalRef.current?.open(rowData.id)}
        >
          <Image
            boxSize='40px'
            objectFit='cover'
            src={cellData}
            alt={cellData}
            rounded={'base'}
          />
        </Box>
      )
    },
    {
      flexGrow: 1,
      key: 'street',
      dataKey: 'street',
      width: 0,
      title: 'Street',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: string
        rowData: RowT
      }) => (
        <Text
          key={rowData.id}
          color={rowData.enabled ? 'gray.900' : 'gray.500'}
        >
          {cellData}
        </Text>
      )
    },
    {
      flexGrow: 1,
      key: 'city',
      dataKey: 'city',
      width: 0,
      title: 'City',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: string
        rowData: RowT
      }) => (
        <Text
          key={rowData.id}
          color={rowData.enabled ? 'gray.900' : 'gray.500'}
        >
          {cellData}
        </Text>
      )
    },
    {
      flexGrow: 1,
      key: 'state',
      dataKey: 'state',
      width: 0,
      title: 'State',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: string
        rowData: RowT
      }) => (
        <Text
          key={rowData.id}
          color={rowData.enabled ? 'gray.900' : 'gray.500'}
        >
          {cellData}
        </Text>
      )
    },
    {
      flexGrow: 1,
      key: 'createdAt',
      dataKey: 'createdAt',
      width: 0,
      title: 'Created At',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: number
        rowData: RowT
      }) => (
        <Text
          key={rowData.id}
          color={rowData.enabled ? 'gray.900' : 'gray.500'}
        >
          {moment(cellData).format('lll')}
        </Text>
      )
    },
    {
      flexGrow: 1,
      key: 'description',
      dataKey: 'description',
      width: 0,
      title: 'Description',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: number
        rowData: RowT
      }) => (
        <Text
          key={rowData.id}
          color={rowData.enabled ? 'gray.900' : 'gray.500'}
        >
          {cellData}
        </Text>
      )
    },
    {
      flexGrow: 1,
      key: 'modelId',
      dataKey: 'modelId',
      width: 0,
      title: 'Matterport model',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: number
        rowData: RowT
      }) => (
        <Link
          href={`https://my.matterport.com/show/?m=${cellData}&play=1&ts=5&qs=1&brand=0&dh=1&gt=1&hr=1&mls=2&mt=0&pin=0&portal=0&f=1&lang=en&nozoom=0&wh=1&kb=1&lp=0&title=0&tourcta=0&vr=1`}
          isExternal
          color={rowData.enabled ? 'gray.900' : 'gray.500'}
          key={rowData.id}
        >
          {cellData} <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </Link>
      )
    },
    {
      // flexGrow: 1,
      key: 'enabled',
      dataKey: 'enabled',
      width: 80,
      title: 'Enabled',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: boolean
        rowData: RowT
      }) => (
        <Switch
          isChecked={cellData}
          onChange={e => toggleTour(rowData.id, e.target.checked)}
        />
      )
    },
    {
      // flexGrow: 1,
      key: 'copy',
      dataKey: 'id',
      width: 80,
      title: 'Tour url',
      cellRenderer: ({ rowData }: { cellData: boolean; rowData: RowT }) => (
        <IconButton
          aria-label='share'
          size='sm'
          onClick={() =>
            copyToClipboard(`${config.customerAppUrl}/${rowData.id}`)
          }
          icon={<FontAwesomeIcon icon={faUpRightFromSquare} size='sm' />}
        />
      )
    }
  ]

  return (
    <Flex flex={1} direction={'row'} grow={1} p={4} ref={containerRef}>
      <BaseTable
        data={data}
        width={width}
        height={height}
        columns={columns}
        ignoreFunctionInColumnCompare={false}
      />
      <TourModelPhotoModal ref={tourModelPhotoModalRef} />
    </Flex>
  )
}

export default TourModelsContent
