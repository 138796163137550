import { useMemo } from 'react'
import _ from 'lodash'
import { Flex, Text, RadioGroup, Stack, Radio } from '@chakra-ui/react'
import moment from 'moment'
import { useSelector } from 'model/hooks'
import BaseTable from 'react-base-table'
import { useMeasure } from 'react-use'
import { dbUpdateDesigner } from 'controllers/designers'
import { DesignerT } from 'types/model'

const DesignersContent = () => {
  const designers = useSelector(state => state.designers)
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()

  const data = useMemo(() => {
    return _.sortBy(designers, ['requestAccess'])
  }, [designers])

  const columns = [
    {
      flexGrow: 1,
      key: 'name',
      dataKey: 'name',
      width: 0,
      title: 'Name',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text>{cellData}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'email',
      dataKey: 'email',
      width: 0,
      title: 'Email',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text>{cellData}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'requestAccess',
      dataKey: 'requestAccess',
      width: 0,
      title: 'Requested At',
      cellRenderer: ({ cellData }: { cellData: number }) => (
        <Text>{moment(cellData).format('lll')}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'approved',
      dataKey: 'isApproved',
      width: 0,
      title: 'Access',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: boolean | undefined
        rowData: DesignerT
      }) => (
        <RadioGroup
          value={_.toString(cellData)}
          onChange={v =>
            dbUpdateDesigner(rowData.id, { isApproved: v === 'true' })
          }
        >
          <Stack spacing={5} direction='row'>
            <Radio colorScheme='red' value='false'>
              Denied
            </Radio>
            <Radio colorScheme='green' value='true'>
              Allowed
            </Radio>
          </Stack>
        </RadioGroup>
      )
    }
  ]

  return (
    <Flex flex={1} direction={'row'} grow={1} p={4} ref={containerRef}>
      <BaseTable
        data={data}
        width={width}
        height={height}
        columns={columns}
        ignoreFunctionInColumnCompare={false}
      />
    </Flex>
  )
}

export default DesignersContent
