import { useMemo } from 'react'
import _ from 'lodash'
import { Flex, Text } from '@chakra-ui/react'
import moment from 'moment'
import { useSelector } from 'model/hooks'
import BaseTable from 'react-base-table'
import { useMeasure } from 'react-use'
import { DictT, ItemT, SupplierT } from 'shared/types/model'
import {
  getItemsBySupplier,
  getEnabledItemsBySupplier
} from 'model/selectors/items'

const SuppliersContent = () => {
  const suppliers: DictT<SupplierT> = useSelector(state => state.suppliers)
  const itemsBySupplier: DictT<DictT<ItemT>> = useSelector(getItemsBySupplier)
  const enabledItemsBySupplier: DictT<DictT<ItemT>> = useSelector(
    getEnabledItemsBySupplier
  )
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()

  const data = useMemo(() => {
    return _.sortBy(suppliers, ['createdAt'])
  }, [suppliers])

  const columns = [
    {
      flexGrow: 1,
      key: 'name',
      dataKey: 'name',
      width: 0,
      title: 'Name',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text>{cellData}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'itemsAmount',
      dataKey: 'id',
      width: 0,
      title: 'Items amount',
      cellRenderer: ({ cellData }: { cellData: string[] }) => {
        const itemsAmount = _.size(_.get(itemsBySupplier, cellData, {}))
        const enabledItemsAmount = _.size(
          _.get(enabledItemsBySupplier, cellData, {})
        )
        return (
          <Text>
            {enabledItemsAmount} / {itemsAmount}
          </Text>
        )
      }
    },
    {
      flexGrow: 1,
      key: 'createdAt',
      dataKey: 'createdAt',
      width: 0,
      title: 'Created At',
      cellRenderer: ({ cellData }: { cellData: number }) => (
        <Text>{moment(cellData).format('lll')}</Text>
      )
    }
  ]

  return (
    <Flex flex={1} direction={'row'} grow={1} p={4} ref={containerRef}>
      <BaseTable
        data={data}
        width={width}
        height={height}
        columns={columns}
        ignoreFunctionInColumnCompare={false}
      />
    </Flex>
  )
}

export default SuppliersContent
