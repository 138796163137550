import {
  onSnapshot,
  query,
  collection,
  doc,
  setDoc,
  updateDoc,
  deleteField,
  deleteDoc,
  writeBatch
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { DictT, ItemsTemplateT } from 'shared/types/model'
import { receiveTemplates } from 'model/actions'

export const fetchTemplates = () => {
  try {
    const q = query(collection(db, 'templates'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<ItemsTemplateT> = {}
        sn.forEach(doc => {
          const p = doc.data() as ItemsTemplateT
          _.set(res, doc.id, p)
        })
        console.log('receive templates ', res)
        store.dispatch(receiveTemplates(res))
      },
      err => {
        console.log(`fetchTemplates: ${err}`)
      }
    )
    addListener('templates', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchTemplates error', e)
  }
}

export const dbCreateTemplate = async (t: ItemsTemplateT) => {
  try {
    const ref = doc(db, `templates/${t.id}`)
    await setDoc(ref, _.omitBy(t, _.isUndefined))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTemplate = async (
  id: string,
  upd: Partial<ItemsTemplateT>
) => {
  try {
    const ref = doc(db, `templates/${id}`)
    await updateDoc(ref, _.omitBy(upd, _.isUndefined))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTemplatePath = async (
  templateId: string,
  path: string,
  value: any
) => {
  try {
    const ref = doc(db, `templates/${templateId}`)
    const update = {
      [path]: value
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteTemplateSlot = async (
  templateId: string,
  tourSlotId: string
) => {
  try {
    const ref = doc(db, `templates/${templateId}`)
    const update = {
      [`slots.${tourSlotId}`]: deleteField()
    }
    await updateDoc(ref, update)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbDeleteTemplate = async (tId: string) => {
  try {
    const ref = doc(db, `templates/${tId}`)
    await deleteDoc(ref)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateTemplatesOrder = async (templates: ItemsTemplateT[]) => {
  const batch = writeBatch(db)
  _.forEach(templates, t => {
    batch.update(doc(db, 'templates', t.id), {
      displayOrder: t.displayOrder
    })
  })
  await batch.commit()
}
