import {
  onSnapshot,
  query,
  collection,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  writeBatch
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveSlots } from 'model/actions'
import { DictT, SlotT } from 'shared/types/model'

export const fetchSlots = () => {
  try {
    const q = query(collection(db, 'slots'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<SlotT> = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveSlots(res))
      },
      err => {
        console.log(`fetchSlots error: ${err}`)
      }
    )
    addListener('slots', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchSlots error', e)
  }
}

export const createSlot = (slot: SlotT) => {
  try {
    setDoc(doc(db, 'slots', slot.id), slot)
  } catch (e) {
    Sentry.captureException(e)
    console.log('createSlot error', e)
  }
}

export const updateSlot = (slotId: string, update: Partial<SlotT>) => {
  try {
    updateDoc(doc(db, 'slots', slotId), update)
  } catch (e) {
    Sentry.captureException(e)
    console.log('createSlot error', e)
  }
}

export const dbDeleteSlot = (slotId: string) => {
  try {
    deleteDoc(doc(db, 'slots', slotId))
  } catch (e) {
    Sentry.captureException(e)
    console.log('dbDeleteSlot error', e)
  }
}

export const dbUpdateSlotsOrder = async (slotsAr: SlotT[]) => {
  const batch = writeBatch(db)
  _.forEach(slotsAr, (s, i) => {
    batch.update(doc(db, 'slots', s.id), { displayOrder: i })
  })
  await batch.commit()
}
