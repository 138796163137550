import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { SupplierT } from 'shared/types/model'
import { createSupplier } from 'controllers/suppliers'

export interface IAddSupplierModal {
  open: () => void
}

type Props = {}

const AddSupplierModal: ForwardRefRenderFunction<IAddSupplierModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  const canCreate = () => {
    return !_.isEmpty(name)
  }

  const onCreateClick = () => {
    const id = generateId()
    const s: SupplierT = {
      id,
      name,
      createdAt: _.now()
    }
    console.log('create supplier', s)
    createSupplier(s)
    setName('')
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add supplier</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel htmlFor='slotName'>Name</FormLabel>
            <Input
              value={name}
              placeholder='item name'
              onChange={e => setName(e.target.value)}
            />
          </FormControl>
          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              colorScheme={'teal'}
              ml={2}
              disabled={!canCreate()}
              onClick={onCreateClick}
            >
              Create
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddSupplierModal)
