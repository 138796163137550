import {
  onSnapshot,
  query,
  collection,
  doc,
  setDoc,
  updateDoc,
  writeBatch
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { DictT, RoomTypeT } from 'shared/types/model'
import { receiveRoomTypes } from 'model/actions'

export const fetchRoomTypes = () => {
  try {
    const q = query(collection(db, 'roomTypes'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<RoomTypeT> = {}
        sn.forEach(doc => {
          const p = doc.data() as RoomTypeT
          _.set(res, doc.id, p)
        })
        console.log('receive room types ', res)
        store.dispatch(receiveRoomTypes(res))
      },
      err => {
        console.log(`fetchRoomTypes: ${err}`)
      }
    )
    addListener('roomTypes', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchRoomTypes error', e)
  }
}

export const dbCreateRoomType = async (rt: RoomTypeT) => {
  try {
    const ref = doc(db, `roomTypes/${rt.id}`)
    await setDoc(ref, _.omitBy(rt, _.isUndefined))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateRoomType = async (id: string, upd: Partial<RoomTypeT>) => {
  try {
    const ref = doc(db, `roomTypes/${id}`)
    await updateDoc(ref, _.omitBy(upd, _.isUndefined))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const dbUpdateRoomTypesOrder = async (rtAr: RoomTypeT[]) => {
  const batch = writeBatch(db)
  _.forEach(rtAr, (s, i) => {
    batch.update(doc(db, 'roomTypes', s.id), { displayOrder: i })
  })
  await batch.commit()
}
