import { useState } from 'react'

import _ from 'lodash'
import { Box, Button } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
// import { ItemsTemplateT, RoomTypeT } from 'types/model'
// import { noImageUrl } from 'shared/constants/helpers'

import { getTemplatesByRoomType } from 'model/selectors/templates'
import { useNavigate, useParams } from 'react-router'
import AdminTemplatesList from 'components/templatesPanel/AdminTemplatesList'
import AdminRoomTypes from 'components/templatesPanel/AdminRoomTypes'

const RoomTypeList = () => {
  const roomTypes = useSelector(state => state.roomTypes)

  const templatesByRoomType = useSelector(getTemplatesByRoomType)
  const [selectedRoomTypeId, setSelectedRoomTypeId] = useState<string | null>(
    null
  )

  const navigate = useNavigate()
  const { templateId } = useParams()

  // const selectedRTId = null

  // const onEditTemplateClick = (
  //   e: MouseEvent<HTMLButtonElement>,
  //   t: ItemsTemplateT
  // ) => {
  //   e.stopPropagation()
  //   console.log('onEditTemplateClick', t)
  //   addTemplateModalRef.current?.open(t.roomTypeId, t.id)
  // }

  // const onAddTemplateClick = (rtId: string) => {
  //   console.log('onAddTemplateClick', rtId)
  //   addTemplateModalRef.current?.open(rtId)
  // }

  // const onTemplateClick = (tId: string) => {
  //   navigate(`/templates/${tId}`)
  // }

  // const renderRow = (rt: RoomTypeT) => {
  //   const isSelected = rt.id === selectedRTId
  //   const rtTemplates = _.get(templatesByRoomType, rt.id)
  //   return (
  //     <AccordionItem key={rt.id}>
  //       <AccordionButton
  //         as='span'
  //         _expanded={{ bg: 'blue.50' }}
  //         role='group'
  //         cursor='pointer'
  //       >
  //         <VStack flex='1' textAlign='left' spacing={0} align='flex-start'>
  //           <HStack align={'center'} justify='flex-start' w='full'>
  //             {/* <HStack maxW={'80%'}> */}
  //             <Image boxSize={10} src={rt.photo || noImageUrl} alt={rt.name} />
  //             <Text
  //               fontSize={'md'}
  //               fontWeight={isSelected ? 900 : 600}
  //               color='blue.800'
  //               lineHeight={1}
  //             >
  //               {rt.name}
  //             </Text>
  //             <Box w={6} h={6}>
  //               <IconButton
  //                 display='none'
  //                 variant='ghost'
  //                 _groupHover={{ display: 'flex' }}
  //                 size='xs'
  //                 aria-label='edit_slot'
  //                 icon={<FontAwesomeIcon icon={faEdit as IconProp} />}
  //                 onClick={e => onEditRTClick(e, rt.id)}
  //               />
  //             </Box>
  //           </HStack>
  //         </VStack>
  //         <AccordionIcon />
  //       </AccordionButton>
  //       <AccordionPanel>
  //         <VStack>
  //           {_.map(rtTemplates, t => {
  //             const isSelected = t.id === templateId
  //             return (
  //               <Flex
  //                 w='full'
  //                 key={t.id}
  //                 // as='button'
  //                 bg={isSelected ? 'blue.100' : undefined}
  //                 _hover={
  //                   isSelected
  //                     ? undefined
  //                     : { bg: 'gray.100', cursor: 'pointer' }
  //                 }
  //                 justify='space-between'
  //                 align={'center'}
  //                 pr={2}
  //                 onClick={() => onTemplateClick(t.id)}
  //                 opacity={t.enabled ? 1 : 0.5}
  //                 role='group'
  //               >
  //                 <HStack>
  //                   <Image
  //                     boxSize={10}
  //                     src={t.photo || noImageUrl}
  //                     alt={t.name}
  //                   />
  //                   <Text
  //                     pl='2'
  //                     py={1}
  //                     fontSize={'xs'}
  //                     textAlign='left'
  //                     lineHeight={1.2}
  //                   >
  //                     {t.name}
  //                   </Text>
  //                   <Box w={6} h={6}>
  //                     <IconButton
  //                       display='none'
  //                       variant='ghost'
  //                       _groupHover={{ display: 'flex' }}
  //                       size='xs'
  //                       aria-label='edit_slot'
  //                       icon={<FontAwesomeIcon icon={faEdit as IconProp} />}
  //                       onClick={e => onEditTemplateClick(e, t)}
  //                     />
  //                   </Box>
  //                 </HStack>
  //               </Flex>
  //             )
  //           })}
  //           <Flex justify={'flex-start'} w='full'>
  //             <Button
  //               size='xs'
  //               variant={'ghost'}
  //               leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
  //               onClick={() => onAddTemplateClick(rt.id)}
  //             >
  //               Add template
  //             </Button>
  //           </Flex>
  //         </VStack>
  //       </AccordionPanel>
  //     </AccordionItem>
  //   )
  // }

  // const renderContent = () => {
  //   if (_.isEmpty(roomTypes)) {
  //     return emptyState
  //   } else {
  //     return (
  //       <Box
  //         h='full'
  //         w='full'
  //         bg='gray.50'
  //         overflowX={'hidden'}
  //         overflowY='auto'
  //       >
  //         <Accordion allowToggle>{_.map(roomTypes, renderRow)}</Accordion>
  //         <Box w='full' flexShrink={0} p={2}>
  //           <Button
  //             size='sm'
  //             w='full'
  //             colorScheme={'blue'}
  //             leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
  //             onClick={onAddRoomTypeClick}
  //             // pt={2}
  //             variant='solid'
  //           >
  //             Add room type
  //           </Button>
  //         </Box>
  //       </Box>
  //     )
  //   }
  // }

  // return (
  //   <Flex h='full' w='72' bg='white' flexShrink={0} direction='column'>
  //     <HStack p={2} spacing={4}>
  //       <IconButton
  //         size='sm'
  //         aria-label='back'
  //         icon={<FontAwesomeIcon icon={faChevronLeft} />}
  //         onClick={() => navigate('/requests')}
  //       />
  //       <Heading size='md'>Templates</Heading>
  //     </HStack>
  //     {renderContent()}
  //     <AddRoomType ref={addRoomTypeModalRef} />
  //     <AddTemplateModal ref={addTemplateModalRef} />
  //   </Flex>
  // )

  const selectRoomType = (rtId: string) => {
    setSelectedRoomTypeId(rtId)
  }

  const toRoomTypes = () => {
    setSelectedRoomTypeId(null)
    navigate('/templates')
  }

  if (templateId) {
    return (
      <Box position='absolute' left={2} top={2} zIndex={100}>
        <Button colorScheme={'blue'} onClick={() => navigate('/templates')}>
          Done
        </Button>
      </Box>
    )
  } else if (selectedRoomTypeId) {
    console.log('Category Items')
    return (
      <AdminTemplatesList
        goBack={toRoomTypes}
        roomType={roomTypes[selectedRoomTypeId]}
        templates={templatesByRoomType[selectedRoomTypeId]}
      />
    )
  } else {
    console.log('return Catalog')
    return (
      <AdminRoomTypes
        goBack={() => navigate('/requests')}
        roomTypes={_.values(roomTypes)}
        selectRoomType={selectRoomType}
      />
    )
  }
}

export default RoomTypeList
