import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState,
  useEffect,
  useMemo
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Image,
  Switch,
  HStack,
  Box,
  IconButton,
  useToast
} from '@chakra-ui/react'
import { Select, OptionBase, GroupBase, SingleValue } from 'chakra-react-select'
import { generateId } from 'controllers/db'
import { FileT, ItemsTemplateT, RoomTypeT } from 'shared/types/model'
import FilesPickerButton from 'shared/components/FilesPickerButton'
import storage from 'controllers/storage'
import { useSelector } from 'model/hooks'
import Resizer from 'react-image-file-resizer'
import { noImageUrl } from 'shared/constants/helpers'
import {
  dbCreateTemplate,
  dbDeleteTemplate,
  dbUpdateTemplate
} from 'controllers/templates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRecycle, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router'
import config from 'shared/config'
import { syncTemplate } from 'controllers/sync'
import FilterTags from 'components/FilterTags'

export interface IAddTemplateModal {
  open: (roomTypeId?: string, templateId?: string) => void
}

interface OptionT extends OptionBase {
  label: string
  value: string
}

type Props = {}

const AddTemplateModal: ForwardRefRenderFunction<IAddTemplateModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const roomTypes = useSelector(state => state.roomTypes)
  const templates = useSelector(state => state.templates)
  const tags = useSelector(state => state.tags)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [rtId, setRtId] = useState<string>()
  const [templateTags, setTemplateTags] = useState<string[]>([])
  const [tId, setTId] = useState<string>()
  const [photo, setPhoto] = useState<string | undefined>()
  const [enabled, setEnabled] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const toast = useToast()
  const navigate = useNavigate()

  useImperativeHandle(ref, () => ({
    open: (rtId?: string, tId?: string) => {
      setRtId(rtId)
      setTId(tId)
      setPhoto(undefined)
      setEnabled(false)
      setTemplateTags([])
      onOpen()
    }
  }))

  useEffect(() => {
    setName(tId && templates ? _.get(templates, [tId, 'name'], '') : '')
    setPhoto(tId && templates ? _.get(templates, [tId, 'photo']) : undefined)
    setEnabled(tId && templates ? _.get(templates, [tId, 'enabled']) : false)
    setTemplateTags(tId && templates ? _.get(templates, [tId, 'tags'], []) : [])
  }, [tId, templates, isOpen])

  const roomTypesOptions = useMemo(() => {
    return _.map(roomTypes, (rt: RoomTypeT) => {
      const opt: OptionT = {
        value: rt.id,
        label: rt.name
      }
      return opt
    })
  }, [roomTypes])

  const optionsValue = useMemo(() => {
    return _.find(roomTypesOptions, opt => opt.value === rtId)
  }, [roomTypesOptions, rtId])

  const onSelectRoomType = (opt: SingleValue<OptionT>) => {
    if (opt) {
      setRtId(opt.value)
    } else {
      setRtId(undefined)
    }
  }

  const canCreate = () => {
    return !_.isEmpty(name)
  }

  const onCreateClick = () => {
    if (tId) {
      const upd: Partial<ItemsTemplateT> = {
        name,
        photo,
        roomTypeId: rtId,
        enabled,
        tags: templateTags
      }
      dbUpdateTemplate(tId, upd)
    } else if (rtId) {
      const t: ItemsTemplateT = {
        id: generateId(),
        name,
        photo,
        roomTypeId: rtId,
        enabled,
        slots: {},
        tags: templateTags
      }
      dbCreateTemplate(t)
    }
    onClose()
  }

  const onPhotosUploaded = (files: FileT[]) => {
    if (_.size(files) > 0) {
      const f = files[0]
      setPhoto(f.url)
    }
  }

  const onDelete = () => {
    if (tId) {
      dbDeleteTemplate(tId)
      onClose()
      navigate('/templates')
    }
  }

  const renderDeleteTemlateButton = () => {
    if (_.isNil(tId)) {
      return <Box />
    } else {
      return (
        <IconButton
          aria-label='delete'
          colorScheme={'red'}
          icon={<FontAwesomeIcon icon={faTrashCan} />}
          onClick={onDelete}
          size='sm'
        />
      )
    }
  }

  const onSync = async () => {
    if (!_.isNil(tId)) {
      setSyncing(true)
      const success = await syncTemplate(tId)
      setSyncing(false)
      toast({
        title: success
          ? 'The template was successfully synced to production DB'
          : 'Sync error: check console log',
        status: success ? 'success' : 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const resizeFile = async (file: File): Promise<File> =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        'JPEG',
        100,
        0,
        f => resolve(f as File),
        'file'
      )
    })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      // closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{tId ? 'Edit template' : 'Add template'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w='full'>
            <FormControl>
              <FormLabel htmlFor='template name'>Template name</FormLabel>
              <Input
                value={name}
                placeholder='Table with chairs'
                onChange={e => setName(e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor='room type'>Room type</FormLabel>
              <Select<OptionT, false, GroupBase<OptionT>>
                colorScheme='white'
                closeMenuOnSelect
                selectedOptionStyle='check'
                hideSelectedOptions={false}
                value={optionsValue}
                options={roomTypesOptions}
                placeholder='Choose room type'
                onChange={v => onSelectRoomType(v)}
                chakraStyles={{
                  menu: provided => ({ ...provided, zIndex: 1000 }),
                  container: provided => ({ ...provided, bgColor: 'white' })
                }}
              />
            </FormControl>
            <HStack w='full'>
              <FilterTags
                tags={tags}
                selectedTags={templateTags || []}
                setSelectedTags={setTemplateTags}
              />
            </HStack>
            <FormControl>
              <FormLabel htmlFor='template is enabled'>Is enabled</FormLabel>
              <Switch
                isChecked={enabled}
                onChange={v => setEnabled(v.target.checked)}
              />
            </FormControl>
          </VStack>

          <VStack pt={8}>
            <Image boxSize={200} src={photo || noImageUrl} alt={'room photo'} />
            <FilesPickerButton
              userId='admin'
              storagePath='/templates'
              onComplete={onPhotosUploaded}
              buttonTitle='Upload template photo'
              thumbnailSize={300}
              inputProps={{
                accept: 'image/*',
                multiple: false
              }}
              storage={storage}
              generateId={generateId}
              fileProcessor={resizeFile}
            />
          </VStack>
          <HStack mb={4} pt={12} w='full' justify={'space-between'}>
            {renderDeleteTemlateButton()}
            <Flex justify='flex-end'>
              {config.isDev && !_.isNil(tId) && (
                <Button
                  leftIcon={<FontAwesomeIcon icon={faRecycle} />}
                  variant={'outline'}
                  ml={2}
                  onClick={onSync}
                  isLoading={syncing}
                >
                  Sync to prod
                </Button>
              )}
              <Button
                colorScheme={'blue'}
                ml={2}
                disabled={!canCreate()}
                onClick={onCreateClick}
              >
                {_.isNil(tId) ? 'Add' : 'Update'}
              </Button>
            </Flex>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddTemplateModal)
