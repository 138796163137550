import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  WrapItem,
  Image,
  Wrap,
  Text,
  Badge,
  Tooltip,
  Spinner,
  TagLabel,
  Tag
} from '@chakra-ui/react'
import ReactGridLayout, { Layout } from 'react-grid-layout'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { ItemT, SlotT, DictT, SupplierT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faGripDotsVertical
} from '@fortawesome/pro-solid-svg-icons'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import { useSelector } from 'model/hooks'
import { arrayMove } from 'shared/utils/array'
import { dbUpdateItemsOrder } from 'controllers/items'

type Props = {
  toCatalog: () => void
  addToRoom: (itemId: string) => void
  selectedItemId: string | null
  openProductCard: (item: ItemT) => void
  slot: SlotT
  items: DictT<ItemT>
  // items: DictT<ItemT>
  suppliers?: DictT<SupplierT>
  onAddItem: () => void
  // getItems?: (
  //   slotId: string,
  //   amount: number,
  //   offset: number
  // ) => Promise<ItemT[]>
}

const AdminCategoryItems: FC<Props> = ({
  toCatalog,
  addToRoom,
  selectedItemId,
  openProductCard,
  slot,
  items,
  suppliers,
  onAddItem
  // getItems
}) => {
  const tags = useSelector(state => state.tags)

  const sortedItems = useMemo(() => {
    return _.sortBy(items, 'displayOrder')
  }, [items])

  const layout = useMemo(() => {
    return _.map(sortedItems, (s, i) => {
      return {
        i: s.id,
        x: i % 2,
        y: Math.trunc(i / 2),
        w: 1,
        h: 1,
        isResizable: false
        // isBounded: true
      }
    })
  }, [sortedItems])

  const onAddToRoomClick = (itemId: string) => {
    addToRoom(itemId)
  }

  const onOpenProductCardClick = (item: ItemT) => {
    console.log('onOpenProductCardClick', item)
    openProductCard(item)
  }

  const renderItem = (item: ItemT) => {
    return (
      // <WrapItem key={item.id} scrollSnapAlign='start'>
      <VStack
        align={'flex-start'}
        pb={4}
        w={'164px'}
        spacing={1}
        key={item.id}
        position={'relative'}
      >
        <Box
          as={item.id !== selectedItemId ? 'button' : undefined}
          // _hover={{ boxShadow: 'md' }}
          onClick={() => onAddToRoomClick(item.id)}
        >
          <Image boxSize={164} alt={item.title} src={getItemPhotoUrl(item)} />
        </Box>
        <Wrap w='full' spacing={0.5}>
          {_.map(item.tags, tId => {
            return (
              <WrapItem key={tId}>
                <Tag
                  size='sm'
                  key={tId}
                  variant='subtle'
                  colorScheme='cyan'
                  mb={0.5}
                >
                  <TagLabel>{tags[tId].name}</TagLabel>
                </Tag>
              </WrapItem>
            )
          })}
        </Wrap>
        <Text
          fontSize={'xs'}
          color='gray.600'
          noOfLines={2}
          h='8'
          lineHeight={1.2}
        >
          {item.title}
        </Text>

        <Flex align='center' justify={'space-between'} w='full' pb={2}>
          {suppliers && item.supplierId && (
            <Tooltip label={_.get(suppliers, [item.supplierId, 'name'])}>
              <Badge colorScheme='green'>
                {_.truncate(_.get(suppliers, [item.supplierId, 'name']), {
                  length: 14
                })}
              </Badge>
            </Tooltip>
          )}
          <Button
            size='xs'
            color='blue.500'
            variant={'unstyled'}
            onClick={() => onOpenProductCardClick(item)}
          >
            more info
          </Button>
        </Flex>
        <HStack w='full'>
          <Button
            size='sm'
            colorScheme={'teal'}
            w='full'
            onClick={() => onAddToRoomClick(item.id)}
            disabled={item.id === selectedItemId}
          >
            Select
          </Button>
          <Box cursor={'grab'}>
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </Box>
        </HStack>
      </VStack>
      // </WrapItem>
    )
  }

  const onDragStop = (layout: Layout[], oldItem: Layout, newItem: Layout) => {
    const oldIndex = oldItem.y * 2 + oldItem.x
    const newIndex = newItem.y * 2 + newItem.x
    const newSortedItems = arrayMove(sortedItems, oldIndex, newIndex)
    const itemsToUpdate: ItemT[] = []
    _.forEach(newSortedItems, (item, i) => {
      if (item.displayOrder !== i) {
        itemsToUpdate.push({ ...item, displayOrder: i })
      }
    })
    if (!_.isEmpty(itemsToUpdate)) {
      dbUpdateItemsOrder(itemsToUpdate)
    }
  }

  const renderItems = () => {
    if (_.isEmpty(items)) {
      return (
        <VStack w='full' h='full' align={'center'} justify='center'>
          <Spinner
            thickness='2px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='sm'
          />
        </VStack>
      )
    } else {
      return (
        <Flex
          w='370px'
          h='full'
          overflowY='auto'
          overflowX={'hidden'}
          position={'relative'}
          pl={0}
        >
          <ReactGridLayout
            // className='layout'
            cols={2}
            rowHeight={330}
            width={370}
            layout={layout}
            margin={[20, 20]}
            onDragStop={onDragStop}
            compactType='horizontal'
          >
            {_.map(items, renderItem)}
          </ReactGridLayout>
        </Flex>
        // <Wrap
        //   spacing={4}
        //   h='full'
        //   overflowY='auto'
        //   scrollSnapType={'y proximity'}
        //   sx={{
        //     '-ms-overflow-style': 'none',
        //     scrollbarWidth: 'none',
        //     '::-webkit-scrollbar': { display: 'none' }
        //   }}
        // >
        //   {_.map(items, renderItem)}
        // </Wrap>
      )
    }
  }

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='flex-start' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <Box as='button' onClick={() => toCatalog()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {slot?.title}
                </Heading>
              </VStack>
            </HStack>
            <Button
              size='xs'
              variant={'ghost'}
              color='blue.500'
              onClick={() => onAddItem()}
            >
              Add item
            </Button>
          </Flex>
        </Box>
        <Flex
          direction='column'
          align={'flex-start'}
          justify='flex-start'
          flex={1}
          // h='full'
          overflow='hidden'
          px={4}
        >
          {renderItems()}
        </Flex>
      </Flex>
    </RightPanelWrapper>
  )
}

export default AdminCategoryItems
