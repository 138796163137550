import _ from 'lodash'
import { useEffect, FC, useRef, useMemo } from 'react'

import { DictT } from 'shared/types/model'
import { MpSdk, Scene } from 'shared/bundle/sdk'
import { Euler, MathUtils, Vector3, Vector3Tuple, Box3, Object3D } from 'three'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { ComponentInteractionType } from 'shared/components/SceneComponent'
import { customTransformControlsType } from 'shared/components/matterport/MTransformControls'

type ItemProps = {
  id: string
  sdk: MpSdk
  url: string
  position?: Vector3
  offset?: Vector3
  rotation?: Vector3Tuple
  scale?: number
  slotSize?: Vector3Tuple
  onMove?: (toPosition: Vector3) => void
  onClick?: () => void
  sceneObject: MpSdk.Scene.IObject
  boundingBox?: Vector3
  setBoundingBox?: (boundingBox: Vector3) => void
  showLoadingIndicator?: boolean
  isSelected?: boolean
  viewMode?: string
  onHover?: () => void
  onBlur?: () => void
  visible?: boolean
  onItemLoaded?: () => void
}

const Item: FC<ItemProps> = ({
  id,
  sdk,
  url,
  position = new Vector3(0, 0, 0),
  offset = new Vector3(0, 0, 0),
  rotation = [0, 0, 0],
  scale = 1,
  slotSize = [1, 1, 1],
  onMove = () => null,
  onClick,
  sceneObject,
  boundingBox,
  setBoundingBox,
  showLoadingIndicator = true,
  isSelected = false,
  viewMode = '',
  onHover,
  onBlur,
  visible = true,
  onItemLoaded
}) => {
  const nodesRef = useRef<DictT<MpSdk.Scene.INode>>({})
  const spiesRef = useRef<DictT<MpSdk.ISubscription>>({})

  useEffect(() => {
    if (nodesRef.current.itemNode) {
      nodesRef.current.itemNode.scale.set(scale, scale, scale)
      checkBoundingBox(nodesRef.current.itemNode)
    }
  }, [scale])

  // useEffect(() => {
  //   if (nodesRef.current.itemNode) {
  //     nodesRef.current.itemNode.checkBoundingBox(nodesRef.current.itemNode)
  //   }
  // }, [scale])

  useEffect(() => {
    // console.log('------------> item', id, 'visible', visible)
    if (nodesRef.current.itemNode) {
      const comps = nodesRef.current.itemNode.componentIterator()
      for (const c of comps) {
        c.inputs.visible = visible
        if (visible) {
          recSetShadows(c.outputs.objectRoot)
        } else {
          recRemoveShadows(c.outputs.objectRoot)
        }
      }
    }
  }, [visible])

  const itemPosition = useMemo(() => {
    return position.clone().add(offset)
  }, [position, offset])

  useEffect(() => {
    if (nodesRef.current.itemNode) {
      // console.log('update item node position', position)
      nodesRef.current.itemNode.position.copy(itemPosition)
      checkBoundingBox(nodesRef.current.itemNode)
    }
    if (nodesRef.current.loadingLogo) {
      nodesRef.current.loadingLogo.position.copy(itemPosition)
    }
  }, [position, offset])

  useEffect(() => {
    if (nodesRef.current.itemNode) {
      // console.log('update item node rotation', rotation)
      const eulerUpdatedRotation = new Euler(
        MathUtils.degToRad(rotation[0]),
        MathUtils.degToRad(rotation[1]),
        MathUtils.degToRad(rotation[2]),
        'XYZ'
      )
      nodesRef.current.itemNode.quaternion.setFromEuler(eulerUpdatedRotation)
      checkBoundingBox(nodesRef.current.itemNode)
    }
  }, [rotation])

  useEffect(() => {
    const transformNode = nodesRef.current.transformNode
    if (transformNode) {
      const comps = transformNode.componentIterator()
      for (const c of comps) {
        // console.log('transform component', c)
        c.inputs.visible = isSelected
        c.inputs.viewMode = viewMode
        c.inputs.showY = viewMode !== 'mode.floorplan'
      }
    }
  }, [viewMode, isSelected])

  const addOnClickListener = (
    node: Scene.INode,
    component: Scene.IComponent
  ) => {
    if (onClick) {
      const evPath = sceneObject.addPath({
        id: `item_click_${id}_${_.now()}`,
        type: 'emit' as Scene.PathType.EMIT,
        node,
        component,
        property: ComponentInteractionType.CLICK
      })
      const spy = {
        path: evPath,
        onEvent: () => {
          onClick()
        }
      }
      spiesRef.current.onClick = sceneObject.spyOnEvent(spy)
    }
  }

  const addOnHoverListener = (
    node: Scene.INode,
    component: Scene.IComponent
  ) => {
    // if (onClick) {
    const evPath = sceneObject.addPath({
      id: `item_hover_${id}_${_.now()}`,
      type: 'emit' as Scene.PathType.EMIT,
      node,
      component,
      property: ComponentInteractionType.HOVER
    })
    const spy = {
      path: evPath,
      onEvent: (e: { hover: boolean }) => {
        if (e.hover) {
          onHover && onHover()
        } else {
          onBlur && onBlur()
        }
      }
    }
    spiesRef.current.onHover = sceneObject.spyOnEvent(spy)
  }

  const addTransformControl = (node: MpSdk.Scene.INode) => {
    // if (isSelected && viewMode !== 'mode.floorplan') {
    // if (isSelected) {
    console.log('%cadd transform controls', 'color: green;')
    const transformNode = sceneObject.addNode()
    nodesRef.current.transformNode = transformNode
    // const transformComponent = transformNode.addComponent(
    //   'mp.transformControls',
    //   {
    //     selection: node,
    //     size: 0.8
    //   }
    // )

    const transformComponent = transformNode.addComponent(
      customTransformControlsType,
      {
        // selection: comp.outputs.objectRoot,
        selection: node.obj3D,
        size: 0.8,
        visible: isSelected,
        viewMode
      }
    )

    // console.log('transformComponent', transformComponent)
    transformNode.start()
    const tc: TransformControls = transformComponent.outputs
      .objectRoot as TransformControls
    tc.addEventListener('mouseUp', () => onMove(node.position.clone()))
    // }
    spiesRef.current.pointer = sdk.Pointer.intersection.subscribe(function (
      intersectionData
    ) {
      const p = intersectionData.position
      transformComponent.inputs.cameraPosition = new Vector3(p.x, 30, p.z)
    })
  }

  const initLoadingIndicator = (
    itemComponent: MpSdk.Scene.IComponent,
    itemNode: MpSdk.Scene.INode
  ) => {
    const loadingLogo = sceneObject.addNode(`loading_logo_${id}_${_.now()}`)
    loadingLogo.position.copy(itemPosition)
    const logoComponent = loadingLogo.addComponent('mp.gltfLoader', {
      url: 'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/Upstager_logo_glb.glb?alt=media&token=d148c55d-3da0-4124-a17c-5a8d0ac92265',
      localScale: { x: 0.6, y: 0.6, z: 0.6 },
      localRotation: { x: 0, y: 0, z: 0 },
      localPosition: { x: 0, y: 0.1, z: 0 }
    })
    // const logoComponent = loadingLogo.addComponent('mp.objLoader', {
    //   url: 'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/brand%20(1).obj?alt=media&token=09a00dbb-8792-4375-9d48-e1d24c10b831',
    //   localScale: { x: 0.01, y: 0.01, z: 0.01 },
    //   localRotation: { x: 0, y: 0, z: 0 },
    //   localPosition: { x: 0, y: 0.2, z: 0 }
    // })

    const loadingIndicatorNode = sceneObject.addNode(
      `loading-indicator_${id}_${_.now()}`
    )
    const loadingComponent = loadingIndicatorNode.addComponent(
      'mp.loadingIndicator',
      {
        size: new Vector3(slotSize[0], slotSize[1], slotSize[2])
      }
    )

    const logoOutputPath = sceneObject.addPath({
      id: `logo_loading_status_listener_${id}_${_.now()}`,
      type: 'output' as Scene.PathType.OUTPUT,
      node: loadingLogo,
      component: logoComponent,
      property: 'objectRoot'
    })
    const logoInputPath = sceneObject.addPath({
      id: `logo_loading_status_listener_input_${id}_${_.now()}`,
      type: 'input' as Scene.PathType.INPUT,
      node: loadingIndicatorNode,
      component: loadingComponent,
      property: 'logo'
    })
    sceneObject.bindPath(logoInputPath, logoOutputPath)

    const laOutputPath = sceneObject.addPath({
      id: `glb_loading_status_listener_${id}_${_.now()}`,
      type: 'output' as Scene.PathType.OUTPUT,
      node: itemNode,
      component: itemComponent,
      property: 'loadingState'
    })
    const laInputPath = sceneObject.addPath({
      id: `glb_loading_status_listener_input_${id}_${_.now()}`,
      type: 'input' as Scene.PathType.INPUT,
      node: loadingIndicatorNode,
      component: loadingComponent,
      property: 'loadingState'
    })
    sceneObject.bindPath(laInputPath, laOutputPath)

    const outputSpy = {
      path: laOutputPath,
      onEvent (type: string) {
        if (type === 'Loaded') {
          checkBoundingBox(itemNode)
        }
      }
    }
    const spyLoading = sceneObject.spyOnEvent(outputSpy)
    spiesRef.current.spyLoading = spyLoading
    nodesRef.current.loadingIndicatorNode = loadingIndicatorNode
    nodesRef.current.loadingLogo = loadingLogo
    loadingLogo.start()
    loadingIndicatorNode.start()
  }

  const checkBoundingBox = (node: MpSdk.Scene.INode) => {
    if (setBoundingBox) {
      const obj = _.get(node, 'obj3D')
      const bbox = new Box3().setFromObject(obj)
      // console.log('checkBoundingBox: bbox', bbox)
      if (obj && _.isFinite(bbox.max.x)) {
        const newBbox = new Vector3(
          bbox.max.x - bbox.min.x,
          bbox.max.y - bbox.min.y,
          bbox.max.z - bbox.min.z
        )
        if (!_.isEqual(boundingBox, newBbox)) {
          setBoundingBox(newBbox)
        }
      }
    }
  }

  const recRemoveShadows = (obj: Object3D) => {
    // console.log('recRemoveShadows obj', obj)
    if (obj) {
      obj.castShadow = false
      obj.receiveShadow = false
      obj.userData.isFurniture = true
      obj.userData.itemId = id
      obj.layers.set(9)
      if (!_.isEmpty(obj.children)) {
        _.forEach(obj.children, recRemoveShadows)
      }
    }
  }

  const recSetShadows = (obj: Object3D) => {
    // console.log('recSetShadows obj', obj)
    obj.castShadow = true
    obj.receiveShadow = true
    obj.userData.isFurniture = true
    obj.userData.itemId = id
    obj.layers.set(8)
    // obj.layers.enable(8)
    if (!_.isEmpty(obj.children)) {
      _.forEach(obj.children, recSetShadows)
    }
  }

  useEffect(() => {
    const run = async () => {
      try {
        const tnow = _.now()
        // console.log(
        //   'init item, position',
        //   position,
        //   'offset',
        //   offset,
        //   'itemPosition',
        //   itemPosition
        // )
        if (!_.isEmpty(nodesRef.current)) {
          _.forEach(nodesRef.current, n => n.stop())
        }
        const node = sceneObject.addNode(`item_preview_${id}_${tnow}`)
        nodesRef.current.itemNode = node
        const onLoaded = (v: Object3D | null) => {
          if (v) {
            console.log('onLoaded', v)
            if (onItemLoaded) {
              onItemLoaded()
            }
            addTransformControl(node)
            v.castShadow = true
            v.receiveShadow = true
            if (visible) {
              recSetShadows(v)
            } else {
              recRemoveShadows(v)
            }
            v.userData.isFurnitureMain = true
            v.userData.isFurniture = true
            v.userData.itemId = id
            // v.renderOrder = 2
            // v.layers.set(8)
            // v.layers.enable(8)

            // console.log('v', v)
            // _.forEach(v.children, (objCh: any) => {
            //   console.log('objCH', objCh)
            //   objCh.castShadow = true
            //   objCh.receiveShadow = true
            // })
            // addTransformControl(node)
          }
          // console.log('onLoaded', v)
        }
        const itemComponent = node.addComponent('mp.gltfLoader', {
          url,
          visible
        })
        const itemComponentOutputPath = sceneObject.addPath({
          id: `item_object_root_${id}_${tnow}`,
          type: 'output' as Scene.PathType.OUTPUT,
          node,
          component: itemComponent,
          property: 'objectRoot'
        })
        const outputSpy = {
          path: itemComponentOutputPath,
          onEvent (o: Object3D | undefined) {
            // console.log('itemComponent objectRoot spy', o)
            if (o) onLoaded(o)
          }
        }
        spiesRef.current.objectRootSpy = sceneObject.spyOnEvent(outputSpy)

        // console.log('itemComponent', itemComponent)

        node.position.copy(itemPosition)
        if (rotation) {
          const eulerUpdatedRotation = new Euler(
            MathUtils.degToRad(rotation[0]),
            MathUtils.degToRad(rotation[1]),
            MathUtils.degToRad(rotation[2]),
            'XYZ'
          )
          node.quaternion.setFromEuler(eulerUpdatedRotation)
        }
        node.name = id
        node.scale.set(scale, scale, scale)
        if (showLoadingIndicator) {
          initLoadingIndicator(itemComponent, node)
        }

        itemComponent.emits = {
          [ComponentInteractionType.CLICK]: true,
          [ComponentInteractionType.HOVER]: true
        }
        addOnClickListener(node, itemComponent)
        addOnHoverListener(node, itemComponent)
        node.start()
      } catch (e) {
        console.warn('item creation error, itemId', id, e)
      }
    }

    run()

    return () => {
      // console.log('Item unmounting')
      _.forEach(nodesRef.current, n => n.stop())
      _.forEach(spiesRef.current, n => n.cancel())
      nodesRef.current = {}
      spiesRef.current = {}
      // const nodes = sceneObject.nodeIterator()
      // console.log('scene object nodes', nodes)
      // for (const n of nodes) {
      // console.log('scene object node', n)
      // }
    }
  }, [])

  return null
}

export default Item
