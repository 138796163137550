import config from 'shared/config'
import { auth } from 'controllers/db'
import * as Sentry from '@sentry/react'

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

export const syncSuppliers = async () => {
  try {
    const url = `${config.backendUrl}/sync/suppliers`
    const currentUser = auth.currentUser
    if (currentUser) {
      const authToken = await currentUser.getIdToken()
      const responseRaw = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify({ authToken })
      })
      console.log('syncSuppliers: responseRaw', responseRaw)
      if (responseRaw) {
        const response = await responseRaw.json()
        console.log('response json', response)
        return response.success
      } else {
        return false
      }
    } else {
      console.warn('syncSuppliers, no current user')
      return false
    }
  } catch (e) {
    Sentry.captureException(e)
    console.warn('syncSuppliers error', e)
    return false
  }
}

export const syncItem = async (itemId: string, syncParams: string[]) => {
  try {
    const url = `${config.backendUrl}/sync/item`
    const currentUser = auth.currentUser
    if (currentUser) {
      const authToken = await currentUser.getIdToken()
      const responseRaw = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify({ authToken, itemId, syncParams })
      })
      console.log('syncItem: responseRaw', responseRaw)
      if (responseRaw) {
        const response = await responseRaw.json()
        console.log('response json', response)
        return response.success
      } else {
        return false
      }
    } else {
      console.warn('syncSuppliers, no current user')
      return false
    }
  } catch (e) {
    Sentry.captureException(e)
    console.warn('syncSuppliers error', e)
    return false
  }
}

export const syncAllItems = async () => {
  try {
    const url = `${config.backendUrl}/sync/all_items`
    const currentUser = auth.currentUser
    if (currentUser) {
      const authToken = await currentUser.getIdToken()
      const responseRaw = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify({ authToken })
      })
      console.log('syncAllItems: responseRaw', responseRaw)
      if (responseRaw) {
        const response = await responseRaw.json()
        console.log('response json', response)
        return response.success
      } else {
        return false
      }
    } else {
      console.warn('syncAllItems, no current user')
      Sentry.captureMessage('syncAllItems, no current user')
      return false
    }
  } catch (e) {
    console.warn('syncAllItems error', e)
    Sentry.captureException(e)
    return false
  }
}

export const syncTemplate = async (templateId: string) => {
  try {
    const url = `${config.backendUrl}/sync/template`
    const currentUser = auth.currentUser
    if (currentUser) {
      const authToken = await currentUser.getIdToken()
      const responseRaw = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify({ authToken, templateId })
      })
      console.log('syncTemplate: responseRaw', responseRaw)
      if (responseRaw) {
        const response = await responseRaw.json()
        console.log('response json', response)
        return response.success
      } else {
        return false
      }
    } else {
      console.warn('syncTemplate, no current user')
      Sentry.captureMessage('syncAllItems, no current user')
      return false
    }
  } catch (e) {
    console.warn('syncTemplate error', e)
    Sentry.captureException(e)
    return false
  }
}

export const calcSlotItems = async (slotId: string) => {
  try {
    const url = `${config.backendUrl}/sync/calc_slot_items`
    const currentUser = auth.currentUser
    if (currentUser) {
      const authToken = await currentUser.getIdToken()
      const responseRaw = await fetch(url, {
        method: 'post',
        headers,
        body: JSON.stringify({ authToken, slotId })
      })
      console.log('calcSlotItems: responseRaw', responseRaw)
      if (responseRaw) {
        const response = await responseRaw.json()
        console.log('response json', response)
        return response.success
      } else {
        return false
      }
    } else {
      console.warn('calcSlotItems, no current user')
      Sentry.captureMessage('syncAllItems, no current user')
      return false
    }
  } catch (e) {
    console.warn('calcSlotItems error', e)
    Sentry.captureException(e)
    return false
  }
}
