import { useRef, useState, FC, ChangeEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/pro-light-svg-icons'
import { Flex, Button, Progress } from '@chakra-ui/react'
import _ from 'lodash'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FileT, StorageFileT, StorageT } from 'shared/types/model'

type Props = {
  onComplete: (files: FileT[]) => void
  storagePath: string
  disabled?: boolean
  inputProps?: object
  userId: string
  buttonTitle?: string
  fileProcessor?: (file: File) => Promise<File>
  thumbnailSize?: number
  storage: StorageT
  generateId: () => string
}

const FilesPickerButton: FC<Props> = ({
  onComplete,
  storagePath,
  disabled = false,
  inputProps = {},
  userId,
  buttonTitle = 'Upload files',
  fileProcessor,
  thumbnailSize = 300,
  storage,
  generateId
}) => {
  const filePickerRef = useRef<HTMLInputElement>(null)
  const [progress, setProgress] = useState<number | undefined>()
  const [inputKey, setInputKey] = useState(_.now())
  const [cancelled, setCancelled] = useState(false)

  const openFilePicker = () => {
    setCancelled(false)
    filePickerRef?.current?.click()
  }

  const mimeIsImage = (contentType: string = '') => {
    return _.startsWith(contentType, 'image')
  }

  const onFilesPicked = async (event: ChangeEvent) => {
    const upl: File[] = _.values(_.get(event, 'target.files'))
    console.log('onFilesPicked', upl)
    const count = _.size(upl)
    const res: FileT[] = []
    for (const i in upl) {
      const fileRaw = fileProcessor ? await fileProcessor(upl[i]) : upl[i]
      if (cancelled) return
      console.log(i, 'fileRaw ===>', fileRaw)
      const id = generateId()
      const p = `${storagePath}/${id}`

      const onProgress = (v: number) => {
        console.log('onProgress', v)
        setProgress(_.ceil(v / count + (Number(i) / count) * 100))
      }
      const fileInfo: StorageFileT | undefined = await storage.saveFileObject(
        fileRaw,
        p,
        onProgress
      )
      console.log('onFilesPicked: fileInfo', fileInfo)
      if (fileInfo) {
        const pFile: FileT = {
          id,
          createdAt: _.now() + Number(i),
          name: _.get(fileRaw, 'name'),
          createdBy: userId,
          ...fileInfo
        }
        if (mimeIsImage(fileInfo.contentType)) {
          const thumbnail: any = await storage.createImageThumbnail(
            fileRaw,
            thumbnailSize
          )
          const thumbnailUrl: string | undefined = _.get(
            await storage.saveFileObject(thumbnail, `${p}_thumbnail`),
            'url'
          )
          if (thumbnailUrl) {
            pFile.thumbnailUrl = thumbnailUrl
          }
        }
        console.log('push file to list', pFile)
        res.push(pFile)
      }
    }

    console.log('%cres', 'color:green', res)
    setInputKey(_.now())

    setProgress(undefined)
    if (cancelled) {
      // deleteFiles(res)
    } else {
      onComplete(res)
    }
  }

  const renderUploadFileButtonSecondary = () => {
    if (!_.isNil(progress)) {
      return (
        <Progress
          w={32}
          size={'sm'}
          value={progress}
          isIndeterminate={progress === 0}
        />
      )
    } else {
      return (
        <Button
          leftIcon={<FontAwesomeIcon icon={faPaperclip as IconProp} />}
          size='sm'
          onClick={openFilePicker}
          disabled={disabled}
        >
          {buttonTitle}
        </Button>
      )
    }
  }

  return (
    <Flex h={8} align='center'>
      {renderUploadFileButtonSecondary()}
      <input
        key={inputKey}
        ref={filePickerRef}
        id='file-input'
        onChange={onFilesPicked}
        type='file'
        style={{ display: 'none' }}
        multiple
        {...inputProps}
      />
    </Flex>
  )
}

export default FilesPickerButton
