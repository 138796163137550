import { configureStore } from '@reduxjs/toolkit'
import authData from 'model/reducers/authData'
import slots from 'model/reducers/slots'
import items from 'model/reducers/items'
import tourModels from 'model/reducers/tourModels'
import suppliers from 'model/reducers/suppliers'
import settings from 'model/reducers/settings'
import designers from 'model/reducers/designers'
import requestsDesign from 'model/reducers/requestsDesign'
import roomTypes from 'model/reducers/roomTypes'
import templates from 'model/reducers/templates'
import mattertags from 'model/reducers/mattertags'
import customers from 'model/reducers/customers'
import tags from 'model/reducers/tags'
import partners from 'model/reducers/partners'

const store = configureStore({
  reducer: {
    authData,
    slots,
    items,
    tourModels,
    suppliers,
    settings,
    designers,
    requestsDesign,
    roomTypes,
    templates,
    mattertags,
    customers,
    tags,
    partners
  }
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
