import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  Image,
  Text
} from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { DictT, SlotT } from 'shared/types/model'

type Props = {
  goBack: () => void
  selectCategory: (slotId: string) => void
  selectedItemId: string | null
  slots: DictT<SlotT>
}

const CatalogPanel: FC<Props> = ({
  goBack,
  selectCategory,
  selectedItemId,
  slots
}) => {
  const sortedSlots = useMemo(() => {
    return _.sortBy(slots, 'displayOrder')
  }, [slots])

  const renderCategories = () => {
    return (
      <Wrap
        spacing={4}
        overflowY='auto'
        scrollSnapType={'y proximity'}
        sx={{
          '-ms-overflow-style': 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {_.map(sortedSlots, s => {
          const isVisible =
            s.itemsAmount && s.itemsAmount > 0 && s.lastItemId !== null
          if (isVisible) {
            return (
              <WrapItem key={s.id} scrollSnapAlign='start'>
                <VStack align={'flex-start'} pb={4}>
                  <Box
                    as='button'
                    _hover={{ boxShadow: 'md' }}
                    onClick={() => selectCategory(s.id)}
                  >
                    <Image
                      boxSize={164}
                      alt={s.title}
                      src={s.photo?.thumbnailUrl || s.photo?.url}
                    />
                  </Box>
                  <Text fontSize={'xs'} color='blackAlpha.900'>
                    {s.title}
                  </Text>
                </VStack>
              </WrapItem>
            )
          }
        })}
      </Wrap>
    )
  }

  const header = (
    <Box pb={8} bg='white' p={4}>
      <Flex align='flex-start' justify={'space-between'}>
        <HStack align='flex-start' spacing={4} w='full'>
          {!selectedItemId && (
            <Box as='button' onClick={() => goBack()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Box>
          )}
          <Flex align={'center'} justify='space-between' w={'full'}>
            <VStack align={'flex-start'}>
              <Heading size='md' color='black'>
                Catalog
              </Heading>
            </VStack>
            {selectedItemId && (
              <Button
                size='xs'
                variant={'ghost'}
                color='blue.500'
                onClick={() => goBack()}
              >
                Done
              </Button>
            )}
          </Flex>
        </HStack>
      </Flex>
    </Box>
  )

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        {header}
        <Flex
          direction='column'
          align={'center'}
          justify='flex-start'
          flex={1}
          h='full'
          overflow={'hidden'}
          p={4}
        >
          {renderCategories()}
        </Flex>
      </Flex>
    </RightPanelWrapper>
  )
}

export default CatalogPanel
