import { createReducer } from '@reduxjs/toolkit'
import { DictT, ItemT } from 'types/model'
import { receiveItems, clear, logout } from 'model/actions'

const initialState = {} as DictT<ItemT>

const itemsReducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveItems, (state, action) => action.payload)
    .addCase(clear, () => initialState)
    .addCase(logout, () => initialState)
})

export default itemsReducer
