import { FC, useEffect, ReactNode } from 'react'
import { Box } from '@chakra-ui/react'

import { appInitialized } from 'controllers/init'
import { useNavigate } from 'react-router-dom'
import config from 'shared/config'
import DevIndicator from 'shared/components/DevIndicator'

const NavWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  useEffect(() => {
    console.log('call appInitialized')
    appInitialized(navigate)
  }, [])

  return (
    <Box w='full' h='100vh'>
      {children}
      {config.isDev && <DevIndicator />}
    </Box>
  )
}

export default NavWrapper
