import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getItems } from 'model/selectors/base'
import { DictT, ItemT } from 'shared/types/model'

export const getItemsBySupplier = createSelector([getItems], items => {
  const res: DictT<DictT<ItemT>> = {}
  _.forEach(items, item => {
    if (item.supplierId) {
      _.set(res, [item.supplierId, item.id], item)
    }
  })
  return res
})

export const getEnabledItemsBySupplier = createSelector([getItems], items => {
  const res: DictT<DictT<ItemT>> = {}
  _.forEach(items, item => {
    if (item.enabled && item.supplierId) {
      _.set(res, [item.supplierId, item.id], item)
    }
  })
  return res
})
