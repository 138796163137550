import { Flex } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import HorizontalCollapse from 'shared/components/HorizontalCollapse'

type Props = {
  children: ReactNode
  show: boolean
  width?: number
  fullHeight?: boolean
  bg?: string
  hideBorder?: boolean
}

const RightPanelWrapper: FC<Props> = ({
  children,
  show,
  width = 340,
  // fullHeight = true,
  bg = 'whiteAlpha.900',
  hideBorder = false
}) => {
  return (
    <HorizontalCollapse isOpen={show} width={width}>
      <Flex
        // h={fullHeight ? 'full' : undefined}
        h='full'
        bg={bg}
        width={`${width}px`}
        maxW={'full'}
        direction={'column'}
        borderLeftWidth={hideBorder ? 0 : 1}
      >
        {children}
      </Flex>
    </HorizontalCollapse>
  )
}

export default RightPanelWrapper
