import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  VStack,
  FormHelperText,
  Image,
  Checkbox,
  Textarea
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { PartnerT, FileT } from 'shared/types/model'
import FilterTags from 'components/FilterTags'
import { useSelector } from 'model/hooks'
import FilesPickerButton from 'shared/components/FilesPickerButton'
import storage from 'controllers/storage'
import { dbCreatePartner } from 'controllers/partners'

export interface IAddPartnerModal {
  open: (p?: PartnerT) => void
}

const EMPTY_CARDS = _.times(3, () => ({
  label: '',
  description: ''
}))

type Props = {}

const AddPartnerModal: ForwardRefRenderFunction<IAddPartnerModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const partners = useSelector(state => state.partners)
  const [partnerId, setPartnerId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [logoUrl, setLogoUrl] = useState<string>()
  const [subdomain, setSubdomain] = useState('')
  const [pricesVisible, setPricesVisible] = useState(false)
  const [authPageText, setAuthPageText] = useState<string | undefined>('')
  const tags = useSelector(state => state.tags)
  const [parnterTags, setPartnerTags] = useState<string[]>([])
  const [ctaText, setCtaText] = useState<string>('')
  const [gettingStartedCards, setGettingStartedCards] = useState<{
    label: string,
    description: string
  }[]>(EMPTY_CARDS)
  const [unlimitedPlans, setUnlimitedPlans] = useState<boolean>(false)

  const isEdit = _.has(partners, partnerId)

  useImperativeHandle(ref, () => ({
    open: (p?: PartnerT) => {
      if (p) {
        setName(p.name)
        setEmail(p.email || '')
        setPhone(p.phone || '')
        setLogoUrl(p.logoUrl)
        setSubdomain(p.subdomain)
        setAuthPageText(p.authPageText)
        setPartnerId(p.id)
        setPartnerTags(p.tags)
        setPricesVisible(p.pricesVisible || false)
        setCtaText(p.ctaText || '')
        setGettingStartedCards(p.gettingStartedCards || EMPTY_CARDS)
        setUnlimitedPlans(p.unlimitedPlans || false)
      } else {
        setName('')
        setEmail('')
        setPhone('')
        setLogoUrl(undefined)
        setSubdomain('')
        setAuthPageText('')
        setPartnerId(generateId())
        setPartnerTags([])
        setPricesVisible(false)
        setCtaText('')
        setGettingStartedCards(EMPTY_CARDS)
        setUnlimitedPlans(false)
      }
      onOpen()
    }
  }))

  const canCreate = () => {
    return !_.isEmpty(name) && !_.isEmpty(subdomain)
  }

  const onCreateClick = () => {
    const p: Partial<PartnerT> = {
      id: partnerId,
      name,
      subdomain,
      authPageText,
      tags: parnterTags,
      logoUrl,
      pricesVisible,
      email,
      phone,
      ctaText,
      gettingStartedCards,
      unlimitedPlans
    }
    if (!isEdit) p.createdAt = _.now()
    console.log('create partner', p)
    dbCreatePartner(p)
    onClose()
  }

  const onLogoUploaded = (files: FileT[]) => {
    if (_.size(files) > 0) {
      const f = files[0]
      setLogoUrl(f.url)
    }
  }

  const onGettingStartedCardUpdated = (index: number, value: string, type: 'label' | 'description') => {
    const newCards = _.cloneDeep(gettingStartedCards)
    newCards[index][type] = value
    setGettingStartedCards(newCards)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isEdit ? 'Edit partner' : 'Add Partner'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack w={'full'} spacing={4} align='flex-start'>
            <FormControl>
              <FormLabel htmlFor='partner name' fontSize='sm' mb={0}>
                Parnter Name
              </FormLabel>
              <Input
                size={'sm'}
                value={name}
                placeholder='Poppin Inc'
                onChange={e => setName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='partner subdomain' fontSize='sm' mb={0}>
                Subdomain
              </FormLabel>
              <Input
                size='sm'
                value={subdomain}
                placeholder='poppin'
                onChange={e => setSubdomain(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='email' fontSize='sm' mb={0}>
                Contact email
              </FormLabel>
              <Input
                size='sm'
                value={email}
                placeholder='demo@example.com'
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='partner phone' fontSize='sm' mb={0}>
                Contact phone
              </FormLabel>
              <Input
                size='sm'
                value={phone}
                placeholder='+1 (500) 555-12-12'
                onChange={e => setPhone(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='Prices are visible'>
                Prices are visible
              </FormLabel>
              <Checkbox
                isChecked={pricesVisible}
                onChange={e => setPricesVisible(e.target.checked)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='Unlimited plan'>
                Unlimited plan
              </FormLabel>
              <Checkbox
                isChecked={unlimitedPlans}
                onChange={e => setUnlimitedPlans(e.target.checked)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='auth pahe text'>Auth page text</FormLabel>
              <Input
                value={authPageText}
                placeholder='Upstager and Poppin teamed up to help commercial...'
                onChange={e => setAuthPageText(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='items tags'>Items tags</FormLabel>
              <FilterTags
                tags={tags}
                selectedTags={parnterTags}
                setSelectedTags={setPartnerTags}
              />
              <FormHelperText>
                Items with the tags will be accociated with the partner and
                availbale to use
              </FormHelperText>
            </FormControl>
            <Image w={52} src={logoUrl} alt={'partner logo'} />
            <FilesPickerButton
              userId='admin'
              storagePath={`/partners/${partnerId}`}
              onComplete={onLogoUploaded}
              buttonTitle={'Upload logo'}
              inputProps={{
                multiple: false,
                accept: 'image/*'
              }}
              storage={storage}
              generateId={generateId}
            />
            <FormControl>
              <FormLabel htmlFor='cta text'>Call to action text</FormLabel>
              <Textarea
                size='sm'
                value={ctaText}
                onChange={e => setCtaText(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='cards text'>Getting started cards text</FormLabel>
              <VStack>
                <VStack w='full' align='start'>
                  <Input
                    size='sm'
                    value={gettingStartedCards[0].label}
                    placeholder='Add Tour'
                    onChange={e => onGettingStartedCardUpdated(0, e.target.value, 'label')}
                  />
                  <Textarea
                    size='sm'
                    value={gettingStartedCards[0].description}
                    placeholder='Go to Matterport and get the
                    tour link of the property you want
                    to virtually stage.'
                    onChange={e => onGettingStartedCardUpdated(0, e.target.value, 'description')}
                  />
                </VStack>
                <VStack w='full'>
                  <Input
                    size='sm'
                    value={gettingStartedCards[1].label}
                    placeholder='Create Design'
                    onChange={e => onGettingStartedCardUpdated(1, e.target.value, 'label')}
                  />
                  <Textarea
                    size='sm'
                    value={gettingStartedCards[1].description}
                    placeholder='Create one or more virtually
                    staged designs for your
                    Matterport tour.'
                    onChange={e => onGettingStartedCardUpdated(1, e.target.value, 'description')}
                  />
                </VStack>
                <VStack w='full'>
                  <Input
                    size='sm'
                    value={gettingStartedCards[2].label}
                    placeholder='Share'
                    onChange={e => onGettingStartedCardUpdated(2, e.target.value, 'label')}
                  />
                  <Textarea
                    size='sm'
                    value={gettingStartedCards[2].description}
                    placeholder='Publish the space with virtual
                    staging on a listing, website,
                    ad,  social media, or email it.'
                    onChange={e => onGettingStartedCardUpdated(2, e.target.value, 'description')}
                  />
                </VStack>
              </VStack>
            </FormControl>
            <Flex mb={4} pt={12} justify='flex-end' w='full'>
              <Button
                colorScheme={'teal'}
                ml={2}
                disabled={!canCreate()}
                onClick={onCreateClick}
              >
                {isEdit ? 'Update' : 'Create'}
              </Button>
            </Flex>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddPartnerModal)
