import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getTemplates } from 'model/selectors/base'
import { DictT, ItemsTemplateT } from 'shared/types/model'

export const getTemplatesByRoomType = createSelector(
  [getTemplates],
  templates => {
    const res: DictT<DictT<ItemsTemplateT>> = {}
    _.forEach(templates, t => {
      _.set(res, [t.roomTypeId, t.id], t)
    })
    return res
  }
)
