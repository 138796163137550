import { ChangeEvent, FC, useEffect, useState, useMemo, useRef } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  FormControl,
  Heading,
  FormLabel,
  Input,
  Flex,
  Textarea,
  VStack,
  Image,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Checkbox,
  AlertIcon,
  Alert,
  Table,
  Th,
  Td,
  Thead,
  Tr,
  Tbody,
  Switch,
  Tooltip,
  Skeleton,
  useToast
} from '@chakra-ui/react'
import numeral from 'numeral'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faPlus,
  faCircleCheck,
  faChevronLeft
} from '@fortawesome/pro-solid-svg-icons'
import { Select, OptionBase, GroupBase, SingleValue } from 'chakra-react-select'
import { Vector3 } from 'three'
import RightPanelWrapper from 'shared/components/RightPanelWrapper'
// import { useSelector } from 'model/hooks'

import { FileT, ItemT, SupplierT } from 'shared/types/model'
import {
  updateItem,
  updateItemField,
  deletePhoto,
  deleteItem
} from 'controllers/items'
import FilesPickerButton from 'shared/components/FilesPickerButton'
import { useSelector } from 'model/hooks'
import { deleteField } from 'firebase/firestore'
import {
  convertInchesToMeters,
  convertMetersToInches
} from 'shared/utils/unitsConvert'
import storage from 'controllers/storage'
import { generateId } from 'controllers/db'
import SlotSwitch from 'pages/itemsEditor/SlotSwitch'
import ItemPanelMenu from 'pages/itemsEditor/ItemPanel/ItemPanelMenu'
// import { syncItem } from 'controllers/sync'
import SyncItemModal, { ISyncItemModal } from 'modals/SyncItemModal'
import ProductCardModal, {
  IProductCardModal
} from 'shared/components/ProductCardModal'
import isURL from 'validator/lib/isURL'
import FilterTags from 'components/FilterTags'

interface OptionT extends OptionBase {
  label: string
  value: string
}

type Props = {
  item: ItemT
  boundingBox: Vector3 | undefined
  unselectItem: () => void
}

const ItemPanel: FC<Props> = ({ item, boundingBox, unselectItem }) => {
  const [title, setTitle] = useState(item.title)
  const [link, setLink] = useState(item.link)
  const [desc, setDesc] = useState(item.desc || '')
  const [price, setPrice] = useState(item.price ? _.toString(item.price) : '')
  const suppliers = useSelector(state => state.suppliers)
  const tags = useSelector(state => state.tags)
  const syncItemModalRef = useRef<ISyncItemModal>(null)
  const productCardRef = useRef<IProductCardModal>(null)
  const [specifications, setSpecifications] = useState<any>(
    item?.specifications || {}
  )
  const [offset0, setOffset0] = useState(
    _.toString(convertMetersToInches(_.get(item, 'model.offset.0', 0)))
  )
  const [offset1, setOffset1] = useState(
    _.toString(convertMetersToInches(_.get(item, 'model.offset.1', 0)))
  )
  const [offset2, setOffset2] = useState(
    _.toString(convertMetersToInches(_.get(item, 'model.offset.2', 0)))
  )
  const [scale, setScale] = useState(
    _.toString(_.get(item, 'model.scale', '1'))
  )
  const [customOpen, setCustomOpen] = useState<boolean>(false)
  const [fieldTitle, setFieldTitle] = useState<string>('')
  const [fieldValue, setFieldValue] = useState<string>('')
  const toast = useToast()

  useEffect(() => {
    setScale(_.toString(_.get(item, 'model.scale', '1')))
  }, [item.model?.scale])

  useEffect(() => {
    setOffset0(
      _.toString(convertMetersToInches(_.get(item, 'model.offset.0', 0)))
    )
    setOffset1(
      _.toString(convertMetersToInches(_.get(item, 'model.offset.1', 0)))
    )
    setOffset2(
      _.toString(convertMetersToInches(_.get(item, 'model.offset.2', 0)))
    )
  }, [item.model?.offset])

  useEffect(() => {
    if (!_.isEqual(item?.specifications, specifications)) {
      setSpecifications(item?.specifications)
    }
  }, [item?.specifications])

  const rotationAxis = useMemo(() => {
    return _.get(item, 'model.rotationAxis', 1)
  }, [item.model?.rotationAxis])

  const suppliersOptions = useMemo(() => {
    return _.map(suppliers, (s: SupplierT) => {
      const opt: OptionT = {
        value: s.id,
        label: s.name
      }
      return opt
    })
  }, [suppliers])

  const onSelectSupplier = (opt: SingleValue<OptionT>) => {
    if (opt) {
      updateItemField(item.id, 'supplierId', opt.value)
    } else {
      updateItemField(item.id, 'supplierId', deleteField())
    }
  }

  const optionsValue = useMemo(() => {
    return _.find(suppliersOptions, opt => opt.value === item.supplierId)
  }, [suppliersOptions, item.supplierId])

  const curPhotos = _.get(item, 'photos', {})

  const saveTitle = () => {
    if (!_.isEmpty(title)) {
      updateItem(item.id, { title })
    } else {
      setTitle(item.title)
    }
  }

  const saveLink = () => {
    if (_.isEmpty(link) || (_.isString(link) && isURL(link))) {
      updateItem(item.id, { link })
    }
  }

  const onPhotosUploaded = (files: FileT[]) => {
    const newFiles = {
      ...curPhotos,
      ..._.keyBy(files, 'id')
    }
    updateItem(item.id, { photos: newFiles })
  }

  const renderSelectPhotoButton = (f: FileT, i: number) => {
    const checked = item.defaultPhotoId ? f.id === item.defaultPhotoId : i === 0
    if (checked) {
      return (
        <Box color='teal'>
          <FontAwesomeIcon icon={faCircleCheck} />
        </Box>
      )
    } else {
      return (
        <Button
          variant={'link'}
          size='xs'
          onClick={() => updateItem(item.id, { defaultPhotoId: f.id })}
        >
          select
        </Button>
      )
    }
  }

  const renderPhotos = () => {
    const files = _.sortBy(curPhotos, 'createdAt')
    return (
      <HStack w='full' overflowY={'hidden'} overflowX='auto' spacing={4}>
        {_.map(files, (f: FileT, i: number) => {
          return (
            <Flex
              w='36'
              h='40'
              key={f.id}
              // borderWidth={1}
              shrink={0}
              // borderRadius='base'
              bg='white'
              align={'center'}
              justify='center'
              position={'relative'}
              // role='group'
              direction='column'
            >
              <Image
                boxSize={36}
                src={_.get(f, 'thumbnailUrl', _.get(f, 'url'))}
                alt={f.name}
              />
              <HStack justify={'space-between'} w='full' pl={2} align='center'>
                {renderSelectPhotoButton(f, i)}
                <IconButton
                  aria-label='remove image'
                  icon={<FontAwesomeIcon icon={faTrash as IconProp} />}
                  variant='unstyled'
                  color={'red'}
                  size='xs'
                  onClick={() => deletePhoto(item.id, f.id)}
                />
              </HStack>
            </Flex>
          )
        })}
      </HStack>
    )
  }

  const onChangeScale = (v: string) => {
    setScale(v)
    const scaleNum = numeral(v).value()
    if (!_.isNil(scaleNum)) {
      updateItemField(item.id, 'model.scale', scaleNum)
    }
  }

  const renderOffsetField = (
    v: string,
    onChange: (v: string) => void,
    title: string
  ) => {
    return (
      <HStack align='center'>
        <Text>{title}</Text>
        <FormControl>
          <NumberInput
            step={1}
            precision={0}
            value={_.round(+v || 0, 2)}
            onChange={onChange}
            size='sm'
            bg='white'
          >
            <NumberInputField id={title} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
      </HStack>
    )
  }

  const renderBoundingBoxField = (v: number | undefined, title: string) => {
    return (
      <HStack align='center'>
        <Text>{title}</Text>
        {v && <Text>{_.round(convertMetersToInches(v), 2).toFixed(2)}</Text>}
        {!v && <Skeleton h='20px' w='100px' />}
      </HStack>
    )
  }

  const setRotationAxis = (n: number) => {
    // console.log('setRotationAxis', n, e)
    updateItemField(item.id, 'model.rotationAxis', n)
  }

  const renderRotationField = (
    n: number,
    onChange: (v: number) => void,
    label: string,
    i: number
  ) => {
    const axisChecked = i === rotationAxis
    // console.log(
    //   'i',
    //   i,
    //   'rotationAxis',
    //   rotationAxis,
    //   'axisChecked',
    //   axisChecked
    // )
    return (
      <HStack w='full' spacing={8} pr={4}>
        <Text>{label}</Text>
        <Slider
          flex='1'
          focusThumbOnChange={false}
          value={n}
          onChange={onChange}
          defaultValue={0}
          min={0}
          max={270}
          step={90}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb fontSize='sm' boxSize='32px'>
            <Text>{n}</Text>
          </SliderThumb>
        </Slider>
        <Checkbox
          isChecked={axisChecked}
          value={i}
          onChange={() => setRotationAxis(i)}
        />
      </HStack>
    )
  }

  const updateOffset = (i: number, v: number) => {
    const curOffset = _.get(item, 'model.offset', [0, 0, 0])
    const newOffset = [...curOffset]
    newOffset[i] = v
    updateItemField(item.id, 'model.offset', newOffset)
  }

  const _setOffset0 = (v: string) => {
    setOffset0(v)
    const num = convertInchesToMeters(numeral(v).value() || 0)
    if (!_.isNil(num)) updateOffset(0, num)
  }

  const _setOffset1 = (v: string) => {
    setOffset1(v)
    const num = convertInchesToMeters(numeral(v).value() || 0)
    if (!_.isNil(num)) updateOffset(1, num)
  }

  const _setOffset2 = (v: string) => {
    setOffset2(v)
    const num = convertInchesToMeters(numeral(v).value() || 0)
    if (!_.isNil(num)) updateOffset(2, num)
  }

  const updateRotation = (i: number, v: number) => {
    const curRotation = _.get(item, 'model.rotation', [0, 0, 0])
    const newRotation = [...curRotation]
    newRotation[i] = v
    updateItemField(item.id, 'model.rotation', newRotation)
  }

  const on3dFileUploaded = (files: FileT[]) => {
    console.log('on3dFileUploaded', files)
    const f = files[0]
    updateItemField(item.id, 'model.glb', f.url)
  }

  const renderModelFileAlert = () => {
    if (!_.has(item, 'model.glb')) {
      return (
        <Box py={2} w='full'>
          <Alert status='error'>
            <AlertIcon />
            There was an error processing your request
          </Alert>
        </Box>
      )
    }
  }

  const addField = () => {
    const newSpecifications = { ...specifications, [fieldTitle]: fieldValue }
    updateItem(item.id, { specifications: newSpecifications })
    closeCustom()
  }

  const removeField = (title: string) => {
    const newSpecifications = { ...specifications }
    delete newSpecifications[title]
    updateItem(item.id, { specifications: newSpecifications })
  }

  const closeCustom = () => {
    setCustomOpen(false)
    setFieldTitle('')
    setFieldValue('')
  }

  const params3D = (
    <VStack py={6} w='full' align={'flex-start'}>
      <Heading size='md' color='blue.800'>
        3D Model
      </Heading>

      <Box pt={4} pb={2}>
        {renderModelFileAlert()}
        <FilesPickerButton
          userId='admin'
          storagePath={`/furniture/${item.id}`}
          onComplete={on3dFileUploaded}
          buttonTitle={
            _.has(item, 'model.glb') ? 'Replace .glb file' : 'Upload .glb file'
          }
          inputProps={{
            multiple: false
          }}
          storage={storage}
          generateId={generateId}
        />
      </Box>

      <FormControl>
        <FormLabel htmlFor='itemScale'>Scale</FormLabel>
        <NumberInput
          min={0}
          step={0.001}
          precision={3}
          value={scale}
          onChange={(strV: string) => onChangeScale(strV)}
          size='sm'
          bg='white'
        >
          <NumberInputField id='amount' />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <FormLabel pt={4} pb={0} htmlFor={'offset'}>
        Offset
      </FormLabel>
      <HStack spacing={6}>
        {renderOffsetField(offset0, _setOffset0, 'X:')}
        {renderOffsetField(offset1, _setOffset1, 'Y:')}
        {renderOffsetField(offset2, _setOffset2, 'Z:')}
      </HStack>

      <FormLabel pt={4} pb={0} htmlFor={'rotation'}>
        Rotation
      </FormLabel>
      <VStack w='full'>
        {renderRotationField(
          _.get(item, 'model.rotation.0', 0),
          (v: number) => updateRotation(0, v),
          'X',
          0
        )}
        {renderRotationField(
          _.get(item, 'model.rotation.1', 0),
          (v: number) => updateRotation(1, v),
          'Y',
          1
        )}
        {renderRotationField(
          _.get(item, 'model.rotation.2', 0),
          (v: number) => updateRotation(2, v),
          'Z',
          2
        )}
      </VStack>
    </VStack>
  )

  const renderCustomInput = () => {
    if (customOpen) {
      return (
        <Box>
          <HStack pt={4} pb={2}>
            <FormControl>
              <FormLabel htmlFor='fieldTitle'>Title</FormLabel>
              <Input
                id='fieldTitle'
                value={fieldTitle}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFieldTitle(e.target.value)
                }
                autoFocus
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='fieldValue'>Value</FormLabel>
              <Input
                id='fieldValue'
                value={fieldValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFieldValue(e.target.value)
                }
              />
            </FormControl>
          </HStack>
          <HStack pt={4} pb={2} alignSelf='end' flex={1}>
            <Button size='sm' onClick={closeCustom}>
              Cancel
            </Button>
            <Button
              size='sm'
              onClick={addField}
              disabled={_.isEmpty(fieldTitle) || _.isEmpty(fieldValue)}
            >
              Save
            </Button>
          </HStack>
        </Box>
      )
    } else {
      return (
        <Box py={4}>
          <Button
            size='sm'
            onClick={() => setCustomOpen(true)}
            leftIcon={<FontAwesomeIcon icon={faPlus as IconProp} />}
          >
            Add new field
          </Button>
        </Box>
      )
    }
  }

  const customField = (
    <VStack pb={12} w='full' align='flex-start'>
      <Heading pb={4} size='sm' color='blue.800'>
        Specifications
      </Heading>
      {_.size(specifications) > 0 && (
        <Table>
          <Thead>
            <Tr p={2}>
              <Th p={2}>Title</Th>
              <Th p={2}>Value</Th>
              <Th p={2}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(specifications, (value: string, key: string) => (
              <Tr key={key} p={2}>
                <Td p={2}>
                  <Text fontSize='sm'>{key}</Text>
                </Td>
                <Td p={2}>
                  <Text fontSize='sm'>{value}</Text>
                </Td>
                <Td p={2}>
                  <IconButton
                    size='sm'
                    aria-label='remove characteristic'
                    icon={<FontAwesomeIcon icon={faTrash as IconProp} />}
                    onClick={() => removeField(key)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      {renderCustomInput()}
    </VStack>
  )

  const toggleItem = (isEnabled: boolean) => {
    console.log('toggleItem', isEnabled)
    updateItemField(item.id, 'enabled', isEnabled)
  }

  const cannotEnable = useMemo(() => {
    return (
      _.isEmpty(item.title) ||
      _.isEmpty(item.supplierId) ||
      _.isEmpty(item.model)
    )
  }, [item])

  const bbox = (
    <HStack pt={6} w='full' align='center' justify={'space-between'}>
      <Heading size='md' color='blue.800'>
        Bounding box
      </Heading>
      <HStack spacing={6} justifyContent='space-between'>
        {renderBoundingBoxField(boundingBox?.x, 'X:')}
        {renderBoundingBoxField(boundingBox?.y, 'Y:')}
        {renderBoundingBoxField(boundingBox?.z, 'Z:')}
      </HStack>
    </HStack>
  )

  const onDelete = async () => {
    const res = await deleteItem(item.id)
    if (res) {
      toast({
        title: 'Item deleted',
        description: `${item.title} has been deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      unselectItem()
    } else {
      toast({
        title: 'Error',
        description: `${item.title} could not be deleted`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }

  const renderSlotSwitch = () => {
    return (
      <FormControl>
        <FormLabel htmlFor='slot'>Slot</FormLabel>
        <SlotSwitch
          value={item.kind}
          onChange={(slotId: string) => updateItem(item.id, { kind: slotId })}
        />
      </FormControl>
    )
  }

  const savePrice = () => {
    const p = _.toNumber(price)
    const v = _.isNaN(p) ? deleteField() : p
    updateItemField(item.id, 'price', v)
  }

  const openProductCard = () => {
    if (item) {
      let supplier
      if (item.supplierId) {
        supplier = _.get(suppliers, item.supplierId)
      }
      productCardRef.current?.open(item, supplier)
    }
  }

  const updateItemTags = (tagsIds: string[]) => {
    if (item) {
      updateItemField(item.id, 'tags', tagsIds)
    }
  }

  const isEnabled = _.get(item, 'enabled', false)

  return (
    <RightPanelWrapper show width={380}>
      <Flex direction={'column'} w='full' px={4} overflow='hidden'>
        <HStack
          borderBottomWidth={1}
          py={2}
          align='center'
          justify='space-between'
        >
          <Box as='button' onClick={() => unselectItem()}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Box>
          <Heading size={'sm'}>{item.title}</Heading>
          <HStack spacing='4'>
            <Tooltip
              hasArrow
              shouldWrapChildren
              mt='3'
              label={
                isEnabled
                  ? undefined
                  : 'Fill required fields: name, supplier, add and configure .glb 3D model to enable the item'
              }
            >
              <Switch
                isChecked={isEnabled}
                onChange={e => toggleItem(e.target.checked)}
                isDisabled={cannotEnable}
              />
            </Tooltip>
          </HStack>
        </HStack>

        <VStack
          spacing={4}
          w='full'
          pt={4}
          align='flex-start'
          overflowX='hidden'
          overflowY={'auto'}
        >
          <HStack align='center' justify={'space-between'} w='full'>
            <Text fontSize={'sm'} color='gray.400'>{`ID: ${item.id}`}</Text>
            <ItemPanelMenu
              onDelete={onDelete}
              onSync={() => syncItemModalRef.current?.open(item.id)}
              onView={openProductCard}
            />
          </HStack>

          <HStack w='full'>
            <FilterTags
              tags={tags}
              selectedTags={item.tags || []}
              setSelectedTags={updateItemTags}
            />
          </HStack>

          <FormControl onBlur={saveTitle} isRequired>
            <FormLabel htmlFor='itemName'>Name</FormLabel>
            <Input
              bg='white'
              value={title}
              placeholder='item name'
              onChange={e => setTitle(e.target.value)}
            />
          </FormControl>

          <FormControl onBlur={() => updateItem(item.id, { desc })}>
            <FormLabel htmlFor='itemDescription'>Description</FormLabel>
            <Textarea
              bg='white'
              size='sm'
              value={desc}
              placeholder='description'
              onChange={e => setDesc(e.target.value)}
            />
          </FormControl>

          <FormControl onBlur={savePrice} isRequired>
            <FormLabel htmlFor='itemPrice'>Price</FormLabel>
            <Input
              bg='white'
              type='number'
              value={price}
              placeholder='item price'
              onChange={e => setPrice(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel htmlFor='itemSupplier'>Supplier</FormLabel>
            <Select<OptionT, false, GroupBase<OptionT>>
              colorScheme='white'
              closeMenuOnSelect
              selectedOptionStyle='check'
              hideSelectedOptions={false}
              value={optionsValue}
              options={suppliersOptions}
              placeholder='Choose supplier'
              onChange={v => onSelectSupplier(v)}
              chakraStyles={{
                menu: provided => ({ ...provided, zIndex: 1000 }),
                container: provided => ({ ...provided, bgColor: 'white' })
              }}
            />
          </FormControl>

          <FormControl onBlur={saveLink}>
            <FormLabel htmlFor='itemLink'>Link to the product page</FormLabel>
            <Input
              bg='white'
              value={link}
              placeholder='Link to the product on the suppliers website'
              isInvalid={!_.isEmpty(link) && !isURL(link || '')}
              onChange={e => setLink(e.target.value)}
            />
          </FormControl>

          {renderSlotSwitch()}

          <Flex direction={'column'} justify='flex-start' w='full'>
            <FormLabel htmlFor='itemPhotos'>Photos</FormLabel>
            {renderPhotos()}
            <Box pt={3}>
              <FilesPickerButton
                userId='admin'
                storagePath={`/furniture/${item.id}`}
                onComplete={onPhotosUploaded}
                buttonTitle='Upload photos'
                inputProps={{
                  accept: 'image/*'
                }}
                storage={storage}
                generateId={generateId}
              />
            </Box>
          </Flex>
          {bbox}
          {params3D}
          {customField}
        </VStack>
        <SyncItemModal ref={syncItemModalRef} />
        <ProductCardModal ref={productCardRef} />
      </Flex>
    </RightPanelWrapper>
  )
}

export default ItemPanel
