import { onSnapshot, query, collection, setDoc, doc } from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { SupplierT } from 'shared/types/model'
import { receiveSuppliers } from 'model/actions'

export const fetchSuppliers = () => {
  try {
    const q = query(collection(db, 'suppliers'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveSuppliers(res))
      },
      err => {
        console.log(`createSupplier error: ${err}`)
      }
    )
    addListener('suppliers', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('createSupplier error', e)
  }
}

export const createSupplier = async (supplier: SupplierT) => {
  try {
    await setDoc(doc(db, 'suppliers', supplier.id), supplier)
  } catch (e) {
    Sentry.captureException(e)
    console.log('createSupplier error', e)
  }
}
