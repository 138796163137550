import {
  onSnapshot,
  query,
  collection,
  setDoc,
  doc,
  updateDoc,
  getDoc
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveTourModels } from 'model/actions'
import { TourModelT, DictT, TourT } from 'shared/types/model'

export const fetchTourModels = () => {
  try {
    const q = query(collection(db, 'tourModels'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<TourModelT> = {}
        sn.forEach(doc => {
          const p = doc.data() as TourModelT
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveTourModels(res))
      },
      err => {
        console.log(`fetchTourModels error: ${err}`)
      }
    )
    addListener('tourModels', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchTourModels error', e)
  }
}

export const createTourModel = async (tourModel: TourModelT) => {
  try {
    await setDoc(doc(db, 'tourModels', tourModel.id), tourModel)
  } catch (e) {
    Sentry.captureException(e)
    console.log('createItem error', e)
  }
}

export const updateTourModelField = async (
  id: string,
  key: string,
  value: any
) => {
  // console.log('updateTourModelField, tourId', id, 'key', key, 'value', value)
  try {
    const upd: any = {
      [key]: value
    }
    await updateDoc(doc(db, 'tourModels', id), upd)
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateItem error', e)
  }
}
export const fetchTourById = async (tourId: string) => {
  try {
    console.log('fetch tour by id', tourId)
    const docRef = doc(db, 'tours', tourId)
    const docSnapshot = await getDoc(docRef)
    if (docSnapshot.exists()) {
      return docSnapshot.data() as TourT
    } else {
      console.log('Tour does not exist')
      return null
    }
  } catch (e) {
    console.log('fetchTourById error', e)
    Sentry.captureException(e)
  }
}
