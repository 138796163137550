import {
  onSnapshot,
  query,
  collection,
  setDoc,
  doc,
  updateDoc,
  deleteField,
  getDocs,
  writeBatch,
  deleteDoc,
  where,
  limit,
  orderBy,
  startAfter
} from 'firebase/firestore'
import _ from 'lodash'
import * as Sentry from '@sentry/react'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveItems } from 'model/actions'
import { ItemT } from 'shared/types/model'

export const fetchItems = () => {
  try {
    const q = query(collection(db, 'furnitures'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveItems(res))
        console.log('receiveItems', res)
      },
      err => {
        console.log(`fetchItems error: ${err}`)
      }
    )
    addListener('items', unsubscribe)
  } catch (e) {
    Sentry.captureException(e)
    console.log('fetchItems error', e)
  }
}

export const createItem = async (item: ItemT) => {
  try {
    await setDoc(doc(db, 'furnitures', item.id), item)
  } catch (e) {
    Sentry.captureException(e)
    console.log('createItem error', e)
  }
}

export const updateItem = async (id: string, update: Partial<ItemT>) => {
  try {
    const upd: any = {}
    _.forEach(update, (v, k) => {
      if (_.isUndefined(v)) {
        upd[k] = deleteField()
      } else {
        upd[k] = v
      }
    })
    await updateDoc(doc(db, 'furnitures', id), upd)
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateItem error', e)
  }
}

export const updateItemField = async (id: string, key: string, value: any) => {
  console.log('updateItemField, itemId', id, 'key', key, 'value', value)
  try {
    const upd: any = {
      [key]: value
    }
    await updateDoc(doc(db, 'furnitures', id), upd)
  } catch (e) {
    Sentry.captureException(e)
    console.log('updateItem error', e)
  }
}

export const deletePhoto = async (itemId: string, photoId: string) => {
  try {
    console.log('delete photo', itemId, photoId)
    const upd = {
      [`photos.${photoId}`]: deleteField()
    }
    await updateDoc(doc(db, 'furnitures', itemId), upd)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const deleteItem = async (id: string) => {
  try {
    const q = query(collection(db, 'tours'))
    const batch = writeBatch(db)
    const qSn = await getDocs(q)
    qSn.forEach(sn => {
      const tour = sn.data()
      const tourId = sn.id
      const newSlots = _.reduce(
        tour.slots,
        (res: any, slot: any) => {
          if (slot.itemId !== id) {
            res[slot.id] = slot
          }
          return res
        },
        {}
      )
      if (!_.isEqual(_.size(tour.slots), _.size(newSlots))) {
        batch.update(doc(db, 'tours', tourId), { slots: newSlots })
      }
    })
    await batch.commit()
    await deleteDoc(doc(db, 'furnitures', id))
    return true
  } catch (e) {
    Sentry.captureException(e)
    console.log('deleteItem error', e)
    return false
  }
}

export const dbUpdateItemsOrder = async (items: ItemT[]) => {
  const batch = writeBatch(db)
  _.forEach(items, item => {
    batch.update(doc(db, 'furnitures', item.id), {
      displayOrder: item.displayOrder
    })
  })
  await batch.commit()
}
export const dbGetItemsBySlot = async (
  slotId: string,
  amount: number,
  offset: number
) => {
  try {
    const state = store.getState()
    const tags = _.get(state, 'partner.tags')
    console.log('dbGetItemsBySlot', slotId, amount, offset, tags)
    let q
    if (!_.isEmpty(tags)) {
      q = query(
        collection(db, 'furnitures'),
        where('kind', '==', slotId),
        where('enabled', '==', true),
        where('tags', 'array-contains-any', tags),
        orderBy('displayOrder', 'asc'),
        startAfter(offset),
        limit(amount)
      )
    } else {
      q = query(
        collection(db, 'furnitures'),
        where('kind', '==', slotId),
        where('enabled', '==', true),
        orderBy('displayOrder', 'asc'),
        startAfter(offset),
        limit(amount)
      )
    }
    const sn = await getDocs(q)
    const items = _.map(sn.docs, doc => doc.data() as ItemT)
    console.log('dbGetItemsBySlot return ', items)
    return items
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return []
  }
}

export const dbFetchItemsList = async (itemsIds: string[]) => {
  try {
    const chunks = _.chunk(itemsIds, 10)
    // console.log('chunks', chunks)
    const itemsAr = await Promise.all(
      _.map(chunks, async ids => {
        const q = query(collection(db, 'furnitures'), where('id', 'in', ids))
        const itemsSN = await getDocs(q)
        return _.map(itemsSN.docs, doc => {
          const p = doc.data() as ItemT
          return p
        })
      })
    )
    console.log('itemsAr', itemsAr)
    const items = _.keyBy(_.flatten(itemsAr), 'id')
    store.dispatch(receiveItems(items))
    const itemsNotFound = _.difference(itemsIds, _.keys(items))
    return itemsNotFound
  } catch (e) {
    Sentry.captureException(e)
    return []
  }
}
