import { FC, useMemo } from 'react'
import _ from 'lodash'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { useSelector } from 'model/hooks'
import ItemsEditor from 'pages/ItemsEditor'
import NavWrapper from 'navigation/NavWrapper'
import TourModels from 'pages/TourModels'
import DashboardWrapper from 'pages/dashboard/DashboardWrapper'
import Suppliers from 'pages/Suppliers'
import SignIn from 'pages/SignIn'
import { getAppLoaded } from 'model/selectors/base'
import Loading from 'pages/Loading'
import Settings from 'pages/Settings'
import Designers from 'pages/Designers'
import RequestsDesign from 'pages/RequestsDesign'
import Templates from 'pages/Templates'
import Tags from 'pages/Tags'
import Partners from 'pages/Partners'

const AppRouter: FC<{}> = () => {
  const authData = useSelector(state => state.authData)
  const appLoaded = useSelector(getAppLoaded)

  const privateRoutes = useMemo(() => {
    if (_.isNil(authData) || !_.isNil(appLoaded)) {
      return <Route path='*' element={<Loading />} />
    } else {
      return (
        <>
          <Route path='/items' element={<ItemsEditor />} />
          <Route path='/templates/:templateId' element={<Templates />} />
          <Route path='/templates' element={<Templates />} />
          <Route path='/' element={<DashboardWrapper />}>
            <Route path='/models' element={<TourModels />} />
            <Route path='/suppliers' element={<Suppliers />} />
            <Route path='/designers' element={<Designers />} />
            <Route path='/requests' element={<RequestsDesign />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/tags' element={<Tags />} />
            <Route path='/partners' element={<Partners />} />
            <Route path='/' element={<Navigate replace to='/models' />} />
            <Route path='*' element={<Navigate replace to='/models' />} />
          </Route>
        </>
      )
    }
  }, [authData, appLoaded])

  return (
    <BrowserRouter>
      <NavWrapper>
        <Routes>
          <Route path='/signin' element={<SignIn />} />
          {privateRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
