import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Divider,
  CheckboxGroup,
  VStack,
  Checkbox,
  useToast
} from '@chakra-ui/react'
import { syncItem } from 'controllers/sync'

export interface ISyncItemModal {
  open: (itemId: string) => void
}

type Props = {}

const SyncItemModal: ForwardRefRenderFunction<ISyncItemModal, Props> = (
  props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [syncing, setSyncing] = useState(false)
  const [itemId, setItemId] = useState<string | undefined>()
  const [syncParams, setSyncParams] = useState<string[]>([
    'name',
    'description',
    'supplier',
    'slot',
    'specifications',
    'photos',
    'model'
  ])

  useImperativeHandle(ref, () => ({
    open: (itemId: string) => {
      setItemId(itemId)
      onOpen()
    }
  }))

  const canCreate = () => {
    return !_.isEmpty(syncParams)
  }

  const apply = async () => {
    if (itemId) {
      setSyncing(true)
      const success = await syncItem(itemId, syncParams)
      setSyncing(false)
      toast({
        title: success
          ? 'The item was successfully synced to production DB'
          : 'Sync error: check console log',
        status: success ? 'success' : 'error',
        duration: 5000,
        isClosable: true
      })
    }
    onClose()
  }

  console.log('itemId', itemId)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sync item to production</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody pt={4}>
          <Text color='gray.600'>Select what needs to be synced</Text>
          <CheckboxGroup
            colorScheme='green'
            value={syncParams}
            onChange={v => setSyncParams(v as string[])}
          >
            <VStack spacing={0} align={'flex-start'} pl={4} pt={4}>
              <Checkbox value='name'>Name</Checkbox>
              <Checkbox value='description'>Description</Checkbox>
              <Checkbox value='supplier'>Supplier</Checkbox>
              <Checkbox value='slot'>Slot</Checkbox>
              <Checkbox value='specifications'>Specifications</Checkbox>
              <Checkbox value='photos'>Photos</Checkbox>
              <Checkbox value='model'>3D model with params</Checkbox>
            </VStack>
          </CheckboxGroup>
          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              colorScheme={'blue'}
              ml={2}
              disabled={!canCreate()}
              onClick={apply}
              isLoading={syncing}
            >
              Sync
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(SyncItemModal)
