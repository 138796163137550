import { AuthError, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import { auth } from 'controllers/db'
import { authErrorToString } from 'shared/utils/stringFirebaseError'

export const dbSignIn = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return null
  } catch (e) {
    const er = e as AuthError
    return authErrorToString(er.code)
  }
}

export const dbSignOut = async (navigate: NavigateFunction) => {
  try {
    if (auth && auth.currentUser) {
      await signOut(auth)
    } else {
      navigate('/signin')
    }
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
  }
}
